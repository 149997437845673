import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.sevice';

@Component({
  selector: 'app-read-more-stable-prime-faculty',
  templateUrl: './read-more-stable-prime-faculty.component.html',
  styleUrls: ['./read-more-stable-prime-faculty.component.scss']
})
export class ReadMoreStablePrimeFacultyComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('blog-read-more-stable-prime-faculty', 'blog-read-more-stable-prime-faculty view loaded', 'VIEW_ENTER')
  }

}
