import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.sevice';

@Component({
  selector: 'app-unlock-success-with-iit-jee-top-scorers',
  templateUrl: './unlock-success-with-iit-jee-top-scorers.component.html',
  styleUrls: ['./unlock-success-with-iit-jee-top-scorers.component.scss']
})
export class UnlockSuccessWithIitJeeTopScorersComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('blog_unlock_success_with_iit_jee_top_scorers', 'blog_unlock_success_with_iit_jee_top_scorers view loaded', 'VIEW_ENTER')
  }

}
