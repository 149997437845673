import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalyticsService } from 'src/app/services/analytics.sevice';

@Component({
  selector: 'app-common',
  templateUrl: './common.component.html',
  styleUrls: ['./common.component.scss']
})
export class CommonComponent implements OnInit {

  @ViewChild('name') name: any;
  @ViewChild('email') email: any;
  @ViewChild('mob') mob: any;
  @ViewChild('schoolname') schoolname: any;
  @ViewChild('boardexamyear') boardexamyear: any;
  @ViewChild('preparefor') preparefor: any;
  @ViewChild('briefaddress') briefaddress: any;
  @ViewChild('state') state: any;
  @ViewChild('city') city: any;
  @ViewChild('message') message: any;
  spinner=false;

  wherefrom = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.wherefrom = params['wherefrom']
      this.analyticsService.trackEvent('form_' + this.wherefrom, this.wherefrom + ' form loaded into view', 'VIEW_ENTER')
  });
  }

  validatePhoneNumber(input_str) 
  {
      var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
      return re.test(input_str);
  }

  send_form(){
    console.log(this.name.nativeElement.value, this.message.nativeElement.value, this.email.nativeElement.value, this.state.nativeElement.value, this.preparefor.nativeElement.value)
    if (!this.validatePhoneNumber(this.mob.nativeElement.value)){
      console.log('Mobile Number Not Valid');
      this.snackBar.open('Mobile Number is not Valid!', 'Close', {duration: 4000});

      return;
    }
    this.spinner = true;
    this.http.post(
      environment.url + '/forms/' + this.wherefrom, 
      {
        name: this.name.nativeElement.value, 
        email: this.email.nativeElement.value,
        mob: this.mob.nativeElement.value,
        schoolname: this.schoolname.nativeElement.value,
        boardexamyear: this.boardexamyear.nativeElement.value,
        preparefor: this.preparefor.nativeElement.value,
        briefaddress: this.briefaddress.nativeElement.value,
        state: this.state.nativeElement.value,
        city: this.city.nativeElement.value,
        message: this.message.nativeElement.value, 
    }).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Caught the error', this.snackBar);
        let snackBarRef = this.snackBar.open('There was some error when accepting your form. Please contact +91-9522188444, 8817775687 for assistance.', 'Home Page', {duration: 10000});
        snackBarRef.onAction().subscribe(() => {
          this.router.navigateByUrl('/');
        })
        this.router.navigateByUrl('/');
        this.spinner = false;
      // Return an observable with a user-facing error message.
        return throwError(() => new Error('We are facing some error; please try again later.'));
      })
    ).subscribe(result => {
      console.log(result)
      let snackBarRef = this.snackBar.open('We have received your enquiry. We will try to reach you shortly', 'Home Page', {duration: 4000});
      snackBarRef.onAction().subscribe(() => {
        this.router.navigateByUrl('/');
      })
      this.spinner = false;
      this.router.navigateByUrl('/');

    })
  }
}
