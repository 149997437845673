<div class="prime-tagline">
  <p>
    <img
      alt="Mentors from IIT"
      src="../../assets/images/static/inverted-left.png"
    />Success Mantra: Select Stable & Seasoned Mentors & Follow them Religiously<img
      alt="Mentors from IIT"
      src="../../assets/images/static/inverted-right.png"
    />
  </p>
</div>
<div class="about-prime hidden-md">
  <!--  [style.height]="get_height()" -->
  <div class="prime-desc">
    <h2 class="pt-5"><span class="pl-4">ABOUT PR</span>IME</h2>
    <div class="container p-4">
      <p>
        Prime Academy, a reputable educational institution in Pune, takes immense pride in its faculty, a team of distinguished IIT graduates. This team is spearheaded by Lalit Kumar, an IIT Bombay alumnus who has mentored over 3000 IITians, and DC Pandey, a renowned author specializing in physics.
      </p>
      <p>
        A distinctive factor setting Prime Academy apart from others is the long-standing stability of our faculty. Prime Academy proudly publishes a recent group photograph of the faculty with an old group pic of the same faculty team, which was clicked a decade back as proof that the Prime Academy team has stayed the same for over a decade.
      </p>
      <p>
        The long-term commitment and expertise of Prime Academy faculty members are the fundamental driving forces behind Prime Academy's stellar success rates in JEE and NEET examinations. Over the past seven years, Prime Academy's track record speaks volumes: from a pool of 1595 students, an impressive 1348 cleared JEE Mains, while a further 567 triumphed over the rigorous JEE Advanced. Prime Academy's performance is exceptional compared to national trends, with approximately 80% of Prime Academy's students clearing JEE Mains (against the typical 10% nationally), and 35% making it through JEE Advanced, surpassing the national success rate of 2%.
      </p>
      <span *ngIf="read_more_1">
        <p>
          The accomplishments of students mentored by Prime Academy are a testament to our teaching prowess. Our alums have spread their wings across various sectors, serving as influential corporate leaders, esteemed bureaucrats, and innovative researchers. Several have excelled in engineering and medical entrance examinations, including notable successes such as Pune's sole IIT JEE All India Rank 1 achiever, Sushant Sachdeva.
        </p>
        <p>
          Parents always exercise great care when choosing an educational institution for their children. Prime Academy has earned the trust of a broad spectrum of such discerning parents, from esteemed IAS/IPS officials and MLAs to respected academics like Deans of IITs, senior military officers, and renowned technocrats to business and corporate world leaders. We are also deeply proud to have served as a guiding force for many underprivileged students, helping them overcome their circumstances and crack the IIT JEE, thereby uplifting not only their own families but their communities and beyond. A notable recognition of Prime Academy's efforts came from the Honourable HRD Minister, who appreciated Prime Academy when 100% of the students from it’s JNV Pune batch, cleared the JEE.  
        </p>
        <p>
          Prime Academy prides itself on a strong word-of-mouth reputation rather than extensive marketing efforts. Most of our students join us through recommendations from former students who've benefited immensely from our coaching. Significantly, many of these students hail from small towns and villages deep within Maharashtra, underlining our reach and impact.
        </p>
        <p>
          At Prime Academy, teaching extends far beyond academic concepts. We place considerable emphasis on keeping our students motivated and engaged. The continuity of the same faculty throughout the course proves crucial in achieving this. However, our commitment doesn't stop at academic accomplishments. We believe in moulding well-rounded individuals who will excel in their chosen careers while also being responsible global citizens, contributing positively everywhere. 
        </p>
        <p>
          Alongside Prime Academy's offline classes for JEE and NEET in Pune, we offer online courses, ensuring learning opportunities for students who may not be able to visit Pune in person.
        </p>
      </span>
      <button class="p-btn-white mb-3 shadow-lg" style="width: 120px !important;" (click)="read_more_1=!read_more_1">Read <span *ngIf="!read_more_1">More</span><span *ngIf="read_more_1">Less</span></button>
      <p>Best Wishes</p>
    </div>
  </div>
</div>

<div class="visible-md-block">
  <!--  [style.height]="get_height()" -->
  <div class="prime-desc-small-device">
    <h2 class="pt-2 text-center"><span class="pl-4">ABOUT PR</span>IME</h2>
    <div class="container p-4">
      <p>
        Prime Academy, a reputable educational institution in Pune, takes immense pride in its faculty, a team of distinguished IIT graduates. This team is spearheaded by Lalit Kumar, an IIT Bombay alumnus who has mentored over 3000 IITians, and DC Pandey, a renowned author specializing in physics.
      </p>
      <p>
        A distinctive factor setting Prime Academy apart from others is the long-standing stability of our faculty. Prime Academy proudly publishes a recent group photograph of the faculty with an old group pic of the same faculty team, which was clicked a decade back as proof that the Prime Academy team has stayed the same for over a decade.
      </p>
      <p>
        The long-term commitment and expertise of Prime Academy faculty members are the fundamental driving forces behind Prime Academy's stellar success rates in JEE and NEET examinations. Over the past seven years, Prime Academy's track record speaks volumes: from a pool of 1595 students, an impressive 1348 cleared JEE Mains, while a further 567 triumphed over the rigorous JEE Advanced. Prime Academy's performance is exceptional compared to national trends, with approximately 80% of Prime Academy's students clearing JEE Mains (against the typical 10% nationally), and 35% making it through JEE Advanced, surpassing the national success rate of 2%.
      </p>
      <span *ngIf="read_more_1">
        <p>
          The accomplishments of students mentored by Prime Academy are a testament to our teaching prowess. Our alums have spread their wings across various sectors, serving as influential corporate leaders, esteemed bureaucrats, and innovative researchers. Several have excelled in engineering and medical entrance examinations, including notable successes such as Pune's sole IIT JEE All India Rank 1 achiever, Sushant Sachdeva.
        </p>
        <p>
          Parents always exercise great care when choosing an educational institution for their children. Prime Academy has earned the trust of a broad spectrum of such discerning parents, from esteemed IAS/IPS officials and MLAs to respected academics like Deans of IITs, senior military officers, and renowned technocrats to business and corporate world leaders. We are also deeply proud to have served as a guiding force for many underprivileged students, helping them overcome their circumstances and crack the IIT JEE, thereby uplifting not only their own families but their communities and beyond. A notable recognition of Prime Academy's efforts came from the Honourable HRD Minister, who appreciated Prime Academy when 100% of the students from it’s JNV Pune batch, cleared the JEE.  
        </p>
        <p>
          Prime Academy prides itself on a strong word-of-mouth reputation rather than extensive marketing efforts. Most of our students join us through recommendations from former students who've benefited immensely from our coaching. Significantly, many of these students hail from small towns and villages deep within Maharashtra, underlining our reach and impact.
        </p>
        <p>
          At Prime Academy, teaching extends far beyond academic concepts. We place considerable emphasis on keeping our students motivated and engaged. The continuity of the same faculty throughout the course proves crucial in achieving this. However, our commitment doesn't stop at academic accomplishments. We believe in moulding well-rounded individuals who will excel in their chosen careers while also being responsible global citizens, contributing positively everywhere. 
        </p>
        <p>
          Alongside Prime Academy's offline classes for JEE and NEET in Pune, we offer online courses, ensuring learning opportunities for students who may not be able to visit Pune in person.
        </p>
      </span>
      <div class="text-center">
        <button class="p-btn-white mb-3 shadow-lg" style="width: 120px !important;" (click)="read_more_1=!read_more_1">Read <span *ngIf="!read_more_1">More</span><span *ngIf="read_more_1">Less</span></button>
      </div>
    </div>
  </div>
</div>
<div class="container pt-3">
  <div class="row">
    <div class="col-xl-3 col-lg-3 col-md-4 col-sm-12 text-center pb-3">
      <div>
        <img
          src="../../assets/images/about-us/director-image.png"
          alt=""
          class="owner-image w-100 hidden-xs"
        />
        <img
          src="../../assets/images/about-us/director-image.png"
          alt=""
          class="owner-image w-50 visible-xs"
        />
      </div>
      <h4>LALIT KUMAR</h4>
      <div class="py-2">
        <div>Founder Chairman and <br />Managing Director<br /></div>
        <div><span>Prime Academy Pune</span></div>
      </div>
    </div>
    <div class="col-xl-9 col-lg-9 col-md-8 col-sm-12 pb-3">
      <div class="hidden-xs" style="margin-top: 50px">
      </div>
      <p>Dear students,</p>
      <p>Aspiring to become a renowned aeronautical scientist, a trailblazing entrepreneur, a dynamic IAS officer, or a successful doctor is a dream shared by many. However, to realize these dreams, certain principles serve as the pillars of success. Many students learn these principles through personal experiences and making mistakes, but it often becomes too late to fulfill these dreams. However, the chances of achieving success are significantly enhanced when you grasp these principles early on with the help of seasoned mentors. That's where Prime Academy comes in. Our esteemed faculty team has remained unchanged for over a decade and has a proven track record in teaching and guiding students to the heights of success. 
      </p>
      <span *ngIf="read_more_2">
        <p>While preparing for national-level exams like JEE/NEET/NDA etc., you are exposed to problem-solving skills, and regular mock tests enhance your critical thinking, time, and stress management skills. Ultimately, these skills help you stay ahead in any field you choose in the long run. Cracking the entrance exam and gaining admission into a prestigious degree college like IIT or AFMC is a by-product of this preparation. At Prime Academy, a significant number of students have been achieving these milestones year after year. With the right mindset and guidance, you too can achieve similar successes as your predecessors. It's important to note that the difference between a successful person and others isn't a lack of strength or knowledge, but rather a lack of will, guidance, and mentoring.
        </p>
        <p>
          Whether you're a diligent student, a parent, or someone interested in expanding your knowledge base, we invite you to discover and utilize what we offer at Prime Academy. We're here to support you every step of the way towards your success.
        </p>
      </span>
      <p>
        <a style="text-decoration: underline; color: blue; cursor: pointer;" (click)="read_more_2=!read_more_2">Read <span *ngIf="!read_more_2">More</span><span *ngIf="read_more_2">Less</span></a><br>
          Wishing you all the best!<br>
        Lalit Kumar,<br> 
        Founder, CMD <br>
        Prime Academy, A division of Prime Tutorials Pvt Ltd
      </p>
    </div>
  </div>
</div>
<div class="gray-div society-message text-center pt-5 pb-3">
  <div class="title-40">Empowering Underprivileged Students: Our 'Educate to Elevate' Initiative</div>
  <div class="desc-2 py-4 px-4">
    <div class="container">
      <div class="row">
        <div class="col">
          <p>
            "Educate to Elevate" captures our belief in education as a transformative force for reshaping individuals and societies. At Prime Academy, we provide deserving students with either fully-funded or subsidized coaching for IIT JEE and NEET. Our initiatives have already benefited numerous students, and we're working on expanding our offerings to include free accommodation and meals. This will empower underprivileged students to concentrate solely on their studies.
          </p>
          <p style="margin-bottom: 0 !important">
            If you know an underprivileged student with the potential and determination to excel academically, kindly introduce them to Prime Academy. We will support their journey to success, at no cost
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div class="container mt-5 nav_bar text-center">
  <div class="row">
    <div class="col pt-3 pb-3 hover_flick" style="font-size: 20px" (click)="toggle(0)" [ngClass]="{'active': active===0, 'bg-dark': active !==0}">About Prime</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" (click)="toggle(1)" [ngClass]="{'active': active===1, 'bg-dark': active !==1}">Director's Message</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" (click)="toggle(2)" [ngClass]="{'active': active===2, 'bg-dark': active !==2}">Faculty Team</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" (click)="toggle(3)" [ngClass]="{'active': active===3, 'bg-dark': active !==3}">Giving Back to Society</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" (click)="toggle(4)" [ngClass]="{'active': active===4, 'bg-dark': active !==4}">Quick Facts</div>
  </div>
</div>

<div *ngIf="active===0" class="container mt-5" style="font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> About Prime </strong></h1>

    <br/>
    <br/>

    <p>In marketing, it is often said, “First time, a good salesman can sell dirt as gold, but the second time, it is the quality of the product, which sells”. This statement is very well justified by our pedagogy. Prime Academy is a pool of IITians with an established record of teaching and is united since so long that we use the group picture of faculties, clicked almost a decade back! Probably we are the only institute in Maharashtra to reach this unique feat. Subsequently a very strong and positive word of mouth helped us keep away from unnecessary marketing/publicity and we could focus just on what we are best at, ie mentoring students.</p>

    <p>With a teaching experience of several years, we have developed the expertise and methodologies required to generate confidence among students to excel in competitive exams. Even in adverse situations like lockdown (due to COVID19), our lectures and mentoring is going on smoothly.</p>

    <p>In the last 3 years, out of 830 students, 684 have cleared JEE mains and 324 cracked JEE Advanced. Last year Mustafa Chasmai aced Pune by securing All India Rank 91 in IIT JEE 2019 and he was the only student from Pune to make it to the International Physics Olympiad camp 2019. The success rates of our students in exams like IIT JEE, NEET, KVPY, NTSE and their feedback endorses our excellence. In terms of success percentage we are ranked very high in Maharashtra. </p>

    <p>With great pride we can boast of a very strong alumni network as all our students remain closely attached with Prime, even after passing out. They regularly visit us to share their first hand experience and to motivate current students. </p>

    <p>Our success mantra is “If our students are successful, we are successful”</p>

    <p>Best Wishes</p>

    <p>&nbsp;</p>
    <p>&nbsp;</p>

  </div>
</div>

<div *ngIf="active===1" class="container mt-5" style="font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Director's Message </strong></h1>

    <br/>
    <br/>
    <p>Dear Students,</p>
    <img class="right_image" src="/static/images/dynamic/2013/04/lalit-dec13-940x1175.jpg" alt="Lalit Kumar, Prime Academy Pune" style="width:calc(101px + 5vw)">
    <br/>

    <p>Hallmark of a successful person is a burning zeal to succeed and correct mentoring.</p>

    <p>Prime Academy doesn’t have any magical wand to make a student successful, but yes, we do have something which matters the most. <strong>An esteemed faculty team of IIT graduates with an established record of teaching and it has remained united since a decade!</strong> This experienced team not just nurtures innovation, creativity and excellence but also guides you with the best possible methodology to learn even the most difficult concepts with ease, in a fun filled environment.</p>

    <p>Regular mock tests with detailed analysis will help you grasp topics and come up with best time & stress management strategies when you face the actual exam or life in general.</p>

    <p>Your learning curve will see many achievements and some disappointments too, but at prime a strong support system will help you to bounce back with vigor and enthusiasm.</p>

    <p>By securing top ranks in exams like IIT JEE/ NEET/ Olympiads, our students have made us proud in the past and we hope to see the juggernaut rolling as you follow the footsteps of your seniors.</p>

    <p>Whether you are a prudent student, a parent or someone interested in improving the knowledge base, we invite you to explore and take advantage of what we offer at Prime.</p>

    <p>All the best!</p>

    <p><strong>Lalit Kumar,</strong><br>

    <strong>Founder Chairman and Managing Director.</strong></p>

    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

</div>

<div *ngIf="active===2" class="container mt-5" style="font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Faculty Team </strong></h1>

    <br/>
    <br/>

    <p><strong>Lalit Kuma</strong>r – B.Tech., Electrical Engineering from<strong> IIT Bombay</strong>, has 11 years of IIT-JEE teaching experience and is well known for his smart methods of teaching across the country. He has the distinct achievement of becoming the youngest author of IEEE publication in 2001 by presenting his technical paper on ‘Power Distribution and Automation’.</p>

    <p><strong>Jaswant Charan</strong> – B.Tech., Mechanical Engineering from <strong>IIT Bombay</strong>. He is very popular among students for his classic method of explicating even extremely difficult concepts in a very simple way. He has over 8 years of IIT-JEE teaching experience.</p>

    <p><strong>Pradeep Nagar</strong> – B.Tech., Electrical Engineering from <strong>IIT Bombay</strong> and has 6 years of IIT-JEE teaching experience. One of the greatest motivator who has mastered multiple techniques of solving the toughest of the toughest problems in easiest possible way.</p>

    <p><strong>Pritam Sengupta</strong> – B.Tech., Instrumentation Engineering from <strong>IIT Kharagpur</strong>. He has 11 years of IIT-JEE teaching experience has vast experience and terrific command over the subject and explains all the concepts in the easiest possible way.</p>

    <p><strong>Rahul Waware</strong> – B.Tech., Electrical Engineering from <strong>IIT Bombay</strong> has 10 years of IIT-JEE teaching experience. This calm and composed gentleman knows best, how to use the mind with maximum efficiency and makes sure that every body understands everything without much of stress.</p>

    <p><strong>Sreedhar Babu</strong> – An extremely enthusiastic aerospace graduate from <strong>IIT Bombay</strong> has over 5 years of IIT-JEE teaching experience. He is the man who makes students feel EUREKA in lectures through friendly styles of teaching mixed with innovation and wit.</p>

    <p><strong>Ashish Anand</strong> – Dual degree from <strong>IIT Bombay</strong> with 8 years of IIT-JEE teaching experience. He is unarguably one of the best teachers of Organic Chemistry in India.</p>

    <p><strong>Mehul Surana</strong> – B.Tech in Electrical Engineering from NIT has over 3 years of experience in teaching. He can track the problems faced by students very easily and teach them the best and easiest possible methods.</p>

    <p><strong>D C Pandey&nbsp; – </strong>The renowned Physics Author and a faculty with many years of experience.</p>

    <p><span style="font-family: ArialMT,serif;"><span style="font-size: small;"><b>R Satyanarayan – </b>He has more than 25 year of IIT JEE Chemistry teaching experience</span></span><span style="font-family: ArialMT,serif;"><span style="font-size: small;"><b>.</b></span></span></p>

    <p><span style="font-family: ArialMT,serif;"><span style="font-size: small;"><b>Nishant Gaurav – </b>B Tech in Chemical Engineering from IIT Kanpur. He has more than 2 years of IIT JEE Mathematics teaching experience.</span></span></p>

    <p><span style="font-family: ArialMT,serif;"><span style="font-size: small;"><b>Manoj Behra&nbsp; – </b>B Tech in bio-technology from NIT Roorkee. He has over 3 years of IIT JEE Chemistry teaching experience.</span></span></p>

    <p><strong>J Chandrasekhar</strong> – A genius in Maths with over 24 years of teaching experience and has guided many students at Ramaiah Classes, Hyderabad and other Corporate colleges of A.P.</p>

    <p>&nbsp;</p>
    <p>&nbsp;</p>

  </div>

</div>

<div *ngIf="active===3" class="container mt-5" style="font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Giving Back To The Society </strong></h1>

    <br/>
    <br/>

    <p>We want to ensure, any needy student should not be deprived of education because of lack of resources or monetary constraints. As of now we are instrumental in giving IIT JEE coaching to many such students free of cost. Very soon we will extend this support to, providing accommodation, food and books for financially weaker section of the society.</p>

    <p>In case you come across any of such genuine case, where student is keen to study and competent to perform but can’t bear the financial burden of studies, please bring that to our notice. We will feel privileged to enroll such students at Prime, without charging any fees. Only thing we require from such students is determination, dedication and discipline!</p>

    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

</div>

<div *ngIf="active===4" class="container mt-5" style="font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Quick Facts About Prime </strong></h1>

    <br/>
    <br/>

    <ul>
      <li>Prime has maximum number of experienced IITians faculties in Pune.</li>
      <li>Prime has amazing continuity of faculties.</li>
      <li>Prime is the fastest growing institute in Maharastra since last 4 years.</li>
      <li>Prime’s USP for enrollment is, it’s word of mouth and hence we don’t need to advertise.</li>
      <li>Prime has six centers in Pune and all centers are given equal importance.</li>
      <li>Rankers batch: We have segregated students between different batches as per their performance, however we have ensured that all batches get equal attention and taught by competent faculty. The difference is mainly in approach. In ranker’s batch basic concepts are discussed for about 60 minute and problems solving is done for about&nbsp;90 minutes in a typical lecture. In regular batches about&nbsp;90 minutes are devoted for basic concepts and about&nbsp;60 minutes for problem solving. It is beneficial to both type of students to get the best out of the lecture.</li>
      <li>Alpha Batch: Few top students from all branches are selected to make a group of 50 students. These students are called at a common center once or twice a month for 4 lectures. This is a dynamic batch which shuffles every month and serves as a motivation to other students to get into this batch.</li>
      <li>We conduct monthly tests and compare the ranking of all students across all branches.</li>
      <li>After the completion of the course we conduct national level tests which are conducted in many other institutes across the country and declare national level ranking for the student.</li>
      <li>Owner of the academy himself is a faculty and hence can monitors processes very closely and takes important decisions without any delay for the betterment of students.</li>
    </ul>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

</div> -->

<!-- <app-footer></app-footer> -->
