import { PopupModalComponent } from './../popup-modal/popup-modal.component';
import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AnalyticsService } from '../services/analytics.sevice';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
})
export class TestimonialsComponent implements OnInit {
  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService, private analyticsService: AnalyticsService) {}
  // testimonials = [
  //   {
  //     rank: 'AIR 01',
  //     name: 'Sushant Sachdeva',
  //     studentImageURL: 'assets/home/Testimonial/Sushant_Sachdeva.jpeg',
  //     college: 'IIT Bombay, PhD at Princeton University, USA, B.Tech Computer Science and Engineering',
  //     testimonialDesc: 'Prof. Lalit was one of the best teachers that I have studied under when preparing for IIT JEE. I am sure many others will benefit from his dedication towards students.'
  //   }, 
  //   {
  //     rank: 'AIR 22',
  //     name: 'Vaibhav Krishnan',
  //     studentImageURL: 'assets/home/Testimonial/vaibhav_krishna.jpg',
  //     college: 'IIT Bombay, B.Tech Computer Science',
  //     testimonialDesc: 'I could never thank Lalit Sir enough for the way he simplified the word "physics" for me. His dynamic approach to the subject converted page long solutions to single steps which was so crucial for me to secure AIR 22 in IIT-JEE where time matters the most. I would strongly recommend Real IIT-JEE aspirants to join Prime Academy under the guidance of Lalit Sir to crack IIT-JEE with the best ranks. Thanking a lot again to Lalit Sir.'
  //   },
  //   {
  //     rank: 'AIR 71',
  //     name: 'Amit Deshmane',
  //     studentImageURL: 'assets/home/Testimonial/Amit_deshmane.jpg',
  //     college: 'IIT Kanpur, B. Tech Computer Science and Engineering',
  //     testimonialDesc: 'When I was in 10th I never thought I can be an IITian. But luckily I got in contact with a genius called Lalit Sir. He made me realize my strengths and motivated me to crack IIT. His immense knowledge made my basics very strong and then he gradually raised my level and made sure that IIT JEE became a child’s play. Throughout my JEE preparation days he kept on encouraging me, which really made a difference. I feel he is a genuine guide and I was lucky to be taught by him. If you have got killing instincts than he will make your path to IIT very simple. Just try.'
  //   },
  //   {
  //     rank: 'AIR 37',
  //     name: 'Ameya Ranade',
  //     studentImageURL: 'assets/home/Testimonial/Ameya_ranade.jpg',
  //     college: 'IIT Bombay, B.Tech Computer Engineering',
  //     testimonialDesc: 'You will seldom find someone who is so dynamic and master of almost all tricks to crack any type of problem in maths and physics. Lalit sir has got his own shortcuts for all topics in maths/physics, which made my IIT JEE preparation a cake walk. I will never forget those days when he went all guns blazing while teaching us electrodynamics. Solved many tough problems in his own style, which is any time superior than methods given in existing books. I am really thankful to him for helping me crack a two-digiti rank in IIT JEE. Sir,best wishes to U & all your lucky students. Sir U rock !!'
  //   },];

  testimonials = [
    {
          rank: 'AIR 01',
          name: 'Sushant Sachdeva',
          studentImageURL: 'assets/home/Testimonial/Sushant_Sachdeva.jpg',
          college: 'IIT Bombay, PhD at Princeton University, USA, B.Tech Computer Science and Engineering',
          testimonialDesc: '<b>Prof. Lalit was one of the best teachers that I have studied under</b> when preparing for IIT JEE. I am sure many others will benefit from his dedication towards students.'
        }, 
        
    {
          rank: 'AIR 22',
          name: 'Vaibhav Krishnan',
          studentImageURL: 'assets/home/Testimonial/vaibhav_krishan.jpg',
          college: 'IIT Bombay, B.Tech Computer Science',
          testimonialDesc: '<b>I could never thank Lalit Sir enough for the way he simplified the word "physics" for me.</b> His dynamic approach to the subject converted page long solutions to single steps which was so crucial for me to secure AIR 22 in IIT-JEE where time matters the most. <b>I would strongly recommend Real IIT-JEE aspirants to join Prime Academy under the guidance of Lalit Sir</b> to crack IIT-JEE with the best ranks. <b>Thanking a lot again to Lalit Sir.</b>'
        },
        
    {
          rank: 'AIR 37',
          name: 'Ameya Ranade',
          studentImageURL: 'assets/home/Testimonial/Ameya_ranade.png',
          college: 'IIT Bombay, B.Tech Computer Engineering',
          testimonialDesc: 'You will seldom find someone who is so <b>dynamic and master of almost all tricks to crack any type of problem in maths and physics. Lalit sir</b> has got his own shortcuts for all topics in maths/physics, which made my IIT JEE preparation a cake walk. I will never forget those days when he went all guns blazing while teaching us electrodynamics. Solved many tough problems in his own style, which is any time superior than methods given in existing books. I am really thankful to him for helping me crack a two-digiti rank in IIT JEE. Sir,best wishes to U & all your lucky students. Sir U rock !!'
        },
    
    {
          rank: 'AIR 44',
          name: 'Sagar Sambrani',
          studentImageURL: 'assets/home/Testimonial/sagar_sambrani.jpg',
          college: 'IIT Bombay, B.Tech Electrical Engineering,Gold medallist IIM Kolkata.',
          testimonialDesc: `Being taught by <b>Lalit Sir has totally changed my attitude towards physics. His teaching makes complex concepts very simple to understand</b> which I feel is a must in a teacher. His lectures are fun to attend & so we could retain what was taught for longer periods of time. We really looked forward to attending Lalit Sir's lectures. I would <b>recommend students to learn Physics from Lalit Sir.</b>`
        },
        
        {
              rank: 'AIR 71',
              name: 'Amit Deshmane',
              studentImageURL: 'assets/home/Testimonial/amit_deshmane.jpg',
              college: 'IIT Kanpur, B. Tech Computer Science and Engineering',
              testimonialDesc: 'When I was in 10th I never thought I can be an IITian. <b>But luckily I got in contact with a genius called Lalit Sir</b>. He made me realize my strengths and motivated me to crack IIT. His immense knowledge <b>made my basics very strong</b> and then he gradually raised my level and made sure that IIT JEE became a child’s play. Throughout my JEE preparation days he kept on encouraging me, which really made a difference. I feel he is a genuine guide and I was lucky to be taught by him. If you have got killing instincts than he will make your path to IIT very simple. Just try.'
            },
    
    {
          rank: 'AIR 75',
          name: 'Prashant Sachdeva',
          studentImageURL: 'assets/home/Testimonial/prashant_sachdeva.jpg',
          college: 'IIT Bombay, B.Tech Computer Science & Engineering',
          testimonialDesc: `<b>I used to attend only Lalit Sir's lecture.</b> His insight into Maths and Physics became my strong point. I am very thankful to him for his guidance and would highly recommend any JEE aspirant to join Prime Academy.`
        },
    
    {
          rank: 'AIR 91',
          name: 'Mustafa Chasmai',
          studentImageURL: 'assets/home/Testimonial/mustafa_chasmai.jpg',
          college: 'IIT Delhi, B.Tech Computer Science',
          testimonialDesc: 'My brother, Huzefa Chasmai, made it to B.Tech Computer Science from IIT Bombay, and I tried following in his footsteps. Because of him, I had the advantage of an early start as I joined Prime Academy in the 9th std. This gave me almost 4 years to practice and analyze many mock tests. <b>The same teachers who taught my brother 7 years ago taught me as well, and we developed a family-like relationship with them, making me very comfortable with them.</b> Though my preparation went well, there were a few phases when I faced problems and needed assistance. <b>Everytime I could easily approach the Prime Academy teachers who helped resolving my issues. Thanks, Prime Academy.</b>'
        },
        
    {
      rank: 'AIR 103',
      name: 'Sumit Kshirsagar',
      studentImageURL: 'assets/home/Testimonial/sumit_kshirsagar.jpg',
      college: 'IIT Delhi B.Tech Electrical Engineering ',
      testimonialDesc: 'Joining Prime Academy was the most crucial factor to crack IIT JEE Lalit sir & Jaswant sir kept me motivated throughout my preparation. Each & every lecture at Prime Academy was delivered with precision & regular mock tests & Doubt Solving sessions kept me on my toes. <b>I would strongly recommend all IIT JEE aspirants to join Prime Academy</b> without getting trapped by many other institutes who might claim my success.'
    },
{
      rank: 'AIR 108',
      name: 'Mayank Kumar Singh',
      studentImageURL: 'assets/home/Testimonial/mayank_singh.jpg',
      college: 'IIT Mumbai B.Tech Electrical Engineering ',
      testimonialDesc: `I wanted to pursue B.Tech from IIT and my parents supported me in all possible ways. Joining <b>Prime Academy's<b> in 10th standard was the most crucial step as I started getting on the right way Fabulous IITian team in the Stewardship of <b>Lalit Sir</b> made my journey very interesting. While preparing there were many ups and downs, but I got all possible support from <b>Prime faculties</b> as they cleared my doubts, helped me to make strategies and boosted my confidence whenever I was low. Solving lots of mock test gave finishing touch to my preparation.`
    },

{
      rank: 'AIR 110',
      name: 'Ankit Jha',
      studentImageURL: 'assets/home/Testimonial/ankit_jha.jpg',
      college: 'IIT Delhi B.Tech Computer Science Engineering ',
      testimonialDesc: 'I came in touch with <b>Lalit sir</b> when I was in 9th Basically I am from Ranchi & have been studying in Persistence in 9th & 10th After every mock test i used to consult Lalit sir. <b>I remember the jaw dropping feeling that mesmerized me in his one liner solutions to tricky and lengthy problems. He is a wizard of physics</b> For the two years of 11th and 12th. I have also attended crash courses from <b>Ashish sir, Rahul sir, Pradeep sir and Shreedhar sir. Rahul sir</b> helped me a lot in improving my weak topics Overall it was a wonderful experience at <b>Prime!</b>'
    },

{
      rank: 'AIR 159',
      name: 'Kedar Joshi',
      studentImageURL: 'assets/home/Testimonial/Ankit_jha.jpg',
      college: 'IIT Mumbai B.Tech Mechanical Engineering ',
      testimonialDesc: 'Always wanted to pursue B. Tech in Mechanical from IIT Mumbai I prepared hard and my parents supported me a lot. But the cutting edge I got was because of faculties at Prime Academy I used to stay at <b>Prime Academy</b> the whole day and exceptionally talented faculties made my concepts strong and motivated me to get a top rank.'
    },

{
      rank: 'AIR 160',
      name: 'Amit Deoda',
      studentImageURL: 'assets/home/Testimonial/PrevImages/AMIT-DEODA-150x150.png',
      college: 'IIT Mumbai B.Tech Computer Science and Engineering ',
      testimonialDesc: `The best thing which I noticed in <b>Lalit sir's teaching was, he pulls a concept from its roots and then uses his own shortcuts to link it with difficult concepts.</b> He does it so smartly by ensuring a relaxed atmosphere that we feel as if we have attended a fun session. Once class is over and then we realize that he has stuffed so many difficult and tricky things in our mind <b>like a magician. That experience cannot be expressed in words</b>, it can only be fit. Thank you sir. Thanks a lot`
    },

{
      rank: 'AIR 183',
      name: 'Devdatta Patankar',
      studentImageURL: 'assets/home/Testimonial/PrevImages/Devdatta-Patankar-150x150.png',
      college: 'IIT Mumbai B.Tech + M. Tech Aerospace Engineering ',
      testimonialDesc: `<b>Lalit Sir used to take our Ranker's batch lectures.</b> Though he must have hardly taught us for 60 odd hours, the impact he carried in such a limited time was amazing. We, as students of Ranker's batch had already completed our syllabus, but the cutting edge that is necessary for the JEE was provided <b>by Lalit Sir. His lectures are a mixed bag of tricks, problems and concepts and a solid base of self-study before the lecture is necessary to keep pace with him.</b> I greatly benefited from his lectures, although I was associated with him for a short duration. Thank you Sir for all the concepts, advice and fun.`
    },
    {
      rank: 'AIR 204',
      name: 'Abhay Gaikwad',
      studentImageURL: 'assets/home/Testimonial/PrevImages/abhay-150x150.png',
      college: 'IIT Mumbai B.Tech Mechanical Engineering ',
      testimonialDesc: 'Always wanted to be an IITian and worked hard for it I used to come to Prime classroom daily in morning and stayed there till 10pm. Prime supported me in all possible ways and even gave me keys of the office so that I could continue my studies even after office hours. Whenever! had a doubt I used to approach the experienced Prime faculty team who themselves are IIT graduates Apart from solving doubts they used to motivate me so much that I got even more passionate about my goal. <b>I am grateful to Prime for making me and my brother IITians.</b>'
    },

{
      rank: 'AIR 244',
      name: 'Prasanna Rawake',
      studentImageURL: 'assets/home/Testimonial/PrevImages/PRASANNA-RAWAKE-150x150.png',
      college: 'IIT Mumbai B.Tech Electrical Engineering ',
      testimonialDesc: 'I did a 2 year classroom program at PRIME ACADEMY and have great regards for LALIT SIR and JASWANT SIR & owe my success to them. <b>Lalit Sir is a wonderful teacher and a great motivator</b> throughout the 2 years. He has a great ability to make students get involved in his subjects & to complete their DREAM Jaswant sir too is good I strongly recommend PRIME to every IIT aspirant.'
    },

{
      rank: 'AIR 261',
      name: 'Mutuza Pathanwala',
      studentImageURL: 'assets/home/Testimonial/Mutuza_pathanwala.jpg',
      college: 'IIT Mumbai B.Tech + M. Tech Electrical Engineering ',
      testimonialDesc: '<b>Lalit sir was a very good math and physics teacher and studying under him was a great privilege</b> He taught us the basic concepts of math and physics so well that cracking JEE became very easy Thank you sir.'
    },

{
      rank: 'AIR 371',
      name: 'Hutukshi Sethna',
      studentImageURL: 'assets/home/Testimonial/Hutukshi_sethna.jpg',
      college: 'IIT Mumbai B.Tech Electrical Engineering ',
      testimonialDesc: '<b>Lalit Sir has been a great inspiration to me.</b> Attending his Physics and Mathis lecture prepared me for facing IIT JEE Both subjects were taught in such a way that all concepts were completely clear. The problems that he discussed in class were challenging. <b>Learning Physics and math’s from him increased my interest in the subjects.</b> I would definitely recommend future JEE aspirants to take the opportunity of learning from him.'
    },

{
      rank: 'AIR 400',
      name: 'Omkar Lokhande',
      studentImageURL: 'assets/home/Testimonial/PrevImages/OMKAR-LOKHANDE-150x150.png',
      college: 'IIT Mumbai B.Tech Mechanical Engineering ',
      testimonialDesc: `Good teachers are the reason why anyone joins a coaching class. One cannot fail to notice marked difference between Lalit Sir's ways of teaching as compared to others. His problem oriented approach helps develop concepts step by step and at the end of the topic you feel really confident about it. I am really thankful to <b>Lalit sir for teaching me math and physics and would recommend any JEE aspirant to Join Prime Academy.</b>`
    },

{
      rank: 'AIR 482',
      name: 'Param Dhar',
      studentImageURL: 'assets/home/Testimonial/param_dhar.jpg',
      college: 'IIT Kharagpur B.Tech Electrical Engineering ',
      testimonialDesc: `I was lucky to be taught by Lalit sir and Jaswant sir. They were zillion times better than any other faculty, I have ever seen. <b>Lalit sir is a gem in physics and math.</b> Jaswant sir is good in chemistry physics. I am extremely happy that they started Prime academy where teaching standards and systematic approach are awesome. Please don't get misguided by any other institute who claims my result just because I took admission there. They should not use my rank, as complete credit of my success goes to Lalit sir and Jaswant sir. I strongly recommend every student to join Prime Academy for best results.`
    },
    
    {
          rank: 'AIR 502',
          name: 'Aniket Inamdar',
          studentImageURL: 'assets/home/Testimonial/aniket_inamdar.jpg',
          college: 'IIT Bombay, B.Tech Mechanical Engineer ',
          testimonialDesc: 'I was greatly influenced by <b>Lalit sir expertise in the fields of Maths and Physics.</b> The conceptual clarity he developed in students is impeccable. Much of the credit of my performance in JEE goes solely to him. I strongly recommended all JEE aspirants to join his coaching institute for guaranteed success in JEE.'
        },
    
    {
          rank: 'AIR 532',
          name: 'Arpit Agarwal',
          studentImageURL: 'assets/home/Testimonial/arpit_agarwal.jpg',
          college: 'IIT Bombay, B.Tech Mechanical Engineer ',
          testimonialDesc: 'I was lucky to be taught at Prime Academy. Choosing the coaching institute is one of the most important decisions. The faculty of the institute is more important than the infrastructure. Jaswant Sir & Lalit Sir focus on the core concepts, which are exactly what IIT JEE, demands.'
        },
    
    {
          rank: 'AIR 597',
          name: 'Abhishek Saini',
          studentImageURL: 'assets/home/Testimonial/PrevImages/ABHISHEK-SAINI-150x150.png',
          college: 'IIT Chennai ',
          testimonialDesc: 'I am very thankful to <b>Prime Academy</b> for everything that this institute has done for me. The faculty here is very good and they teach us how to approach a tricky problem and solve it using the basic concepts. All IIT aspirants must have their minds and concepts crystal clear and this is where <b>"Prime Academy" will have you the most.</b>'
        },
    
    {
          rank: 'AIR 636',
          name: 'Ankur Singh',
          studentImageURL: 'assets/home/Testimonial/ankur_singh.jpg',
          college: 'IIT Kanpur , B.Tech Electrical Engineering' ,
          testimonialDesc: ' It was my dream to pursue mechanical engineering from IIT Bombay and <b>Prime Academy</b> helped my dream come true. The faculty here is awesome and they helped me whenever i faced any problem. I would recommend <b>Prime Academy</b> to every JEE aspirant. It is truly a <b>mecca of JEE preparation.</b>'
        },
    
    {
          rank: 'AIR 638',
          name: 'Aviral Bhatnagar',
          studentImageURL: 'assets/home/Testimonial/aviral_bhatnagar.jpg',
          college: 'IIT Mumbai B.Tech Engineering Physics ',
          testimonialDesc: 'Nowadays, IIT JEE is more about the approach and examination skills. It becomes very important to choose the correct Institute for coaching. At <b>Prime Academy</b> I honed these skills under the guidance of Lalit sir and Jaswant sir. Finally this ensured my success in the examination.'
        },
    
    
    
    
    ];
  ngOnInit(): void {
    this.analyticsService.trackEvent('testimonials', 'testimonials loaded into view', 'VIEW_ENTER')
  }
  openTestimonialViewAllOrVideoModal(
    index: number,
    viewAll: boolean,
    viewVideo: boolean
  ) {
    const initialState = {
      isTestimonialViewAllView: viewAll,
      isTestimonialVideoView: viewVideo,
      isMediaOrGallery: false,
      testimonialItem: this.testimonials[index],
      testimonialItems: this.testimonials,
      currentIndex: index,
    };
    this.bsModalRef = this.modalService.show(PopupModalComponent, {
      initialState,
      class: 'modal-lg',
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
