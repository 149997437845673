<article id="meet_team">
  <div class="faculty__white_bg py-2">
    <header class="faculty__header mt-4">MEET OUR TEAM!</header>
    <div class="text-center">
      <img src="../../assets/home/Carousel/best-jee-coaching-pune-prime-academy-pune.jpg" class="w-33 desktop-only">
      <img src="../../assets/home/Carousel/best-jee-coaching-pune-prime-academy-pune.jpg" class="w-100 mobile-only">
    </div>
    <!-- <div class="container">
      <div class="row">
        <div class="col text-center">
        </div>
      </div>
    </div> -->
    <div class="faculty__desc m-2 px-5 pt-3">
      The Prime Academy faculty team has remained almost unchanged for over a decade.
      This stability serves as the driving force behind the academy's remarkable success ratio in JEE/NEET 
    </div>
  </div>

  <div class="container facultys">
    <div class="row mx-5 faculty">
      <div
        class="col-xl-4 col-lg-4 col-md-6 col-sm-12 mb-4"
        *ngFor="let item of facultys"
      >
        <div class="slide">
          <img
            alt="1000+ alumni in IIT"
            [src]="item.image"
          />
            <!-- ../../assets/images/students/director-image.png -->
          <div class="faculty-name">{{item.name}}</div>
          <p class="faculty-education">
            <!-- IIT Bombay, PhD at Princeton University, USA, B.Tech Computer Science and Engineering -->
            {{item.univ}}
          </p>
          <p class="faculty-words">
            {{item.desc}}
            <!-- He has 18 years of IIT-JEE teaching experience and is well known for
            his smart methods of teaching across the country. He has the
            distinct achievement of becoming one of the youngest authors of IEEE
            publication in 2001 by presenting his technical paper on ‘Power
            Distribution and Automation’. -->
          </p>
        </div>
      </div>
    </div>
  </div>
</article>
