<div class="covid-alert">
  <div class="container p-4">
    <div class="row">
      <div class="col-md-2"></div>
      <div class="col-md-8">
        <p>{{ activeCourse.banner }}</p>
      </div>
    </div>
  </div>
</div>
<div id="combobox-wrapper">
  <div class="dropdown">
    <button
      class="block dropdown-toggle"
      type="button"
      id="menu1"
      data-toggle="dropdown"
    >
      {{ activeCourse.title }} <span class="caret"></span>
    </button>
    <ul class="dropdown-menu" role="menu" aria-labelledby="menu1">
      <li role="presentation" *ngFor="let item of courseService.courses; let i = index">
        <a class="m-1" href="#" [routerLink]="['/courses/' + i]">{{
          item.title | uppercase
        }}</a>
      </li>
    </ul>
  </div>
</div>
<div class="d-flex m-4" id="course-wrapper">
  <div class="bg-light" id="sidebar-wrapper">
    <!-- <div class="sidebar-heading">Therichpost </div> -->
    <div class="list-group list-group-flush">
      <a
        href="#"
        *ngFor="let item of courseService.courses; let i = index"
        [routerLink]="['/courses/' + i]"
        [class.active]="i === courseId"
        class="list-group-item list-group-item-action bg-light"
        >{{ item.title | uppercase }}</a
      >
    </div>
  </div>
  <div id="page-content-wrapper">
    <div class="program-banner">
      <h2>{{ activeCourse.title | uppercase }}</h2>
      <p>{{ activeCourse.description }}</p>
    </div>
    <ul class="course-details">
      <li>
        <div class="title-18-blue">
          <img src="../../assets/images/programs/eligibility-icon.png" /><span
            >ELIGIBITY CRITERIA</span
          >
        </div>
        <p>{{ activeCourse.eligibility }}</p>
      </li>
      <li>
        <div class="title-18-blue">
          <img src="../../assets/images/programs/duration-icon.png" /><span
            >COURSE DURATION</span
          >
        </div>
        <p>{{ activeCourse.duration }}</p>
      </li>
      <li>
        <div class="title-18-blue">
          <img src="../../assets/images/programs/frequency-icon.png" /><span
            >LECTURE FREQUENCY</span
          >
        </div>
        <p>{{ activeCourse.frequency }}</p>
      </li>
    </ul>
    <!-- {{activeCourse|json}} -->
    <div class="course-item">
      <div class="title-18-blue">COVERAGE</div>
      <p class="my-2">{{ activeCourse.coverage }}</p>
    </div>
    <div class="course-item">
      <div class="title-18-blue">TESTS</div>
      <p class="my-2">{{ activeCourse.test }}</p>
    </div>
    <div class="course-item">
      <div class="title-18-blue">DOUBTS</div>
      <p class="my-2">{{ activeCourse.doubts }}</p>
    </div>
    <div class="course-item">
      <div class="title-18-blue">Fees</div>
      <p>{{ activeCourse.fees }}</p>
    </div>

    <!-- <div class="frequently-asked-qa">
      <div class="title-40">Frequently Asked <span>Questions</span></div>
      <div class="bs-example pt-4">
        <div class="accordion" id="accordionExample">
          <div class="card" *ngFor="let item of QAs; let x = index">
            <div class="card-header" id="headingOne{{ x }}">
              <h2 class="mb-0">
                <button
                  type="button"
                  class="btn btn-link"
                  data-toggle="collapse"
                  [attr.data-target]="'#C' + x"
                >
                  {{ item.question
                  }}<i
                    class="fa"
                    [ngClass]="{
                      'fa-chevron-up': colapse[x],
                      'fa-chevron-down': !colapse[x]
                    }"
                  ></i>
                </button>
              </h2>
            </div>
            <div
              [id]="'C' + x"
              class="collapse"
              aria-labelledby="headingOne{{ x }}"
              data-parent="#accordionExample"
              [class.show]="x == 0"
            >
              <div class="card-body">
                <p>{{ item.answer }}</p>
                <p *ngIf="item?.answer1">{{ item.answer1 }}</p>
                <p *ngIf="item?.answer2">{{ item.answer2 }}</p>
                <p *ngIf="item?.answer3">{{ item.answer3 }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>

  <!-- <app-footer></app-footer> -->
  <!-- <div id="nav_bar" class="container mt-5 nav_bar text-center">
  <div id="nav_bar_actual" class="row">
    <div id="bridge_course" class="col pt-3 pb-3 hover_flick active" style="font-size: 20px" onclick="toggle(this, 'bridge')">Summer (Bridge) course:</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, '2_year_adv')">JEE-2022- Two Year Course </div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, '2_year_neet')">CET-2022- Two Year course</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, '1_year_power')">JEE 2021-One Year Course</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, 'rank_booster')">Repeaters (RP1/RP2)</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, 'crash_course')">Crash Course, JEE Mains and Adv</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, 'foundation_course')">Foundation Course(7th, 8th& 9th appeared students</div>
    <div class="col pt-3 pb-3 hover_flick bg-dark" style="font-size: 20px" onclick="toggle(this, 'Correspondance_course')">Correspondance Course, IIT & NEET</div>
  </div>
</div>

<div id="bridge" class="container mt-5 pb-5" style="visibility: visible; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1 style="text-align:center"><strong>Summer (Bridge) course</strong></h1>
    <br/>
    <p style="text-decoration: underline; text-align: center;"><strong>For whom: This course is for students who have appeared for 10th board exams in Year 2020.</strong></p>
    <p style="text-decoration: underline; text-align: center;">Those who register for JEE/NEET 2022 are by default eligible to attend this course.</p>
    <br>
    <p style="text-decoration: underline;"><strong>Coverage:</strong></p>
    <p>In these sessions we cover the very basic fundamentals of maths and science of 11th standard primarily to bridge up the gap between differences in levels of school studies till class 10th and competitive exam requirements. It will also give a glimpse of the rich academic culture which Prime Academy has developed over the years with highly experienced IITian Faculty. Apart from the subject, we discuss lots of aptitude and mental ability problems to stimulate their brain, which helps them to tackle tricky problems of exams like JEE. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Eligibility criteria:</strong></p>
    <p>Should have appeared for 10th board.</p>
    <br>
    <p style="text-decoration: underline;"><strong>Course duration:</strong></p>
    <p>Duration: 4 weeks. There are multiple batches starting on different dates in April 2020. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Lecture frequency:</strong></p>
    <p>5 weekly sessions of 90 minute each.</p>
    <br>
    <p style="text-decoration: underline;"><strong>Tests:</strong></p>
    <p>At the end of the course, there will be one Grand test and in between the course there will be 3 unit tests. </p>
    <br>
    <p><i>Because of COVID-19 Lockdown, lectures are being conducted online.</i></p>

    <p>This course is being conducted in phases. To attend next available demo session, register <a href="https://docs.google.com/forms/d/e/1FAIpQLScM4Tg2q6NmUMoyGwsI_t2nW9zTqPHQdwoa4mB1BavmpnruOA/viewform?usp=sf_link" target="_blank" style="text-decoration: underline; color:blue;"> Here</a></p>

  </div>
</div>

<div id="2_year_adv" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1 style="text-align:center"><strong>JEE-2022- Two Year Course</strong></h1>
    <br/>
    <p style="text-decoration: underline; text-align: center;"><strong>For whom: This course is for students who have appeared for 10th board exams in Year 2020. </strong></p>
    <br>
    <p style="text-decoration: underline;"><strong>Coverage:</strong></p>
    <p>The course is designed for competitive exams like JEE (Mains & Advanced), BITSAT, MH-CET and board exams (CBSE, State & ICSE).  </p>
    <br>
    <p style="text-decoration: underline;"><strong>Eligibility criteria:</strong></p>
    <p>Need to clear the Prime Academy admission test or should have scored more than 85% in Math & Science in 10th board exams. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Course duration:</strong></p>
    <p>Two Years. There are multiple batches starting on different dates in April 2020. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Lecture frequency:</strong></p>
    <p>6 weekly sessions of 2.5-3 hour duration. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Tests:</strong></p>
    <p>Prime Monthly Test (PMT) is conducted and students are given ranks based on their relative performance among all batches of Prime. Apart from PMT, regular Unit tests (UT) are also conducted after the completion of every topic.  </p>
    <br>
    <p style="text-decoration: underline;"><strong>Doubts:</strong></p>
    <p>Doubts are catered through multiple channels like Prime online portals, whatsapp groups and scheduled doubt session of faculties before or after lectures. </p>
    <br>

    <p style="text-decoration: underline;"><strong>Fee Structure:</strong></p>
    <p> To checkout the <strong>fee structure</strong> click <a style="text-decoration: underline; color:blue;" href="https://www.primeacademypune.com/fees/JEE2022TwoYear">Here</a></p>
    <br>

    <p><i>Because of COVID-19 Lockdown, lectures are being conducted online. Once things get normal, lectures will be conducted in classrooms in Pune. Those who can not visit Pune and wish to continue online, can do so and will be charged 25% less fee.</i></p>

    <p>This course is being conducted in phases. To attend next available demo session, register <a href="https://docs.google.com/forms/d/e/1FAIpQLScM4Tg2q6NmUMoyGwsI_t2nW9zTqPHQdwoa4mB1BavmpnruOA/viewform?usp=sf_link" target="_blank" style="text-decoration: underline; color:blue;"> Here</a></p>

  </div>

</div>

<div id="2_year_neet" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1 style="text-align:center"><strong> CET-2022- Two Year course</strong></h1>
    <br/>
    <p style="text-decoration: underline; text-align: center;"><strong>For whom: This course is for students who have appeared for 10th board exams in Year 2020. </strong></p>
    <br>
    <p style="text-decoration: underline;"><strong>Coverage:</strong></p>
    <p>The course is designed for state entrance exams like MH CET and board exams (CBSE, State & ICSE).   </p>
    <br>
    <p style="text-decoration: underline;"><strong>Eligibility criteria:</strong></p>
    <p>Should have appeared for 10th board.  </p>
    <br>
    <p style="text-decoration: underline;"><strong>Course duration:</strong></p>
    <p>Two Years. Starts from mid may 2020  </p>
    <br>
    <p style="text-decoration: underline;"><strong>Lecture frequency:</strong></p>
    <p>6 weekly sessions of 2 hour duration.  </p>
    <br>
    <p style="text-decoration: underline;"><strong>Tests:</strong></p>
    <p>Prime Monthly Test (PMT) is conducted and students are given ranks based on their relative performance among all batches of Prime. Apart from PMT, regular Unit tests (UT) are also conducted after the completion of every topic. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Doubts:</strong></p>
    <p>Doubts are catered through multiple channels like Prime online portals, whatsapp groups and scheduled doubt session of faculties before or after lectures. </p>
    <br>

    <p><i>Because of COVID-19 Lockdown, lectures are being conducted online. Once things get normal, lectures will be conducted in classrooms in Pune. Those who can not visit Pune and wish to continue online can do so and will be charged 25% less fee.</i></p>

    <p>This course is being conducted in phases. To attend next available demo session, register <a href="https://docs.google.com/forms/d/e/1FAIpQLScM4Tg2q6NmUMoyGwsI_t2nW9zTqPHQdwoa4mB1BavmpnruOA/viewform?usp=sf_link" target="_blank" style="text-decoration: underline; color:blue;"> Here</a></p>

  </div>

</div>

<div id="1_year_power" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1 style="text-align:center"><strong>JEE 2021-One Year Course</strong></h1>
    <br/>
    <p style="text-decoration: underline; text-align: center;"><strong>For whom: This course is for students who have appeared 11th exams in 2020. </strong></p>
    <br>
    <p style="text-decoration: underline;"><strong>Coverage:</strong></p>
    <p>The course is designed for competitive exams like JEE (Mains & Advanced), BITSAT, MH-CET and board exams (CBSE, State & ICSE). </p>
    <br>
    <p style="text-decoration: underline;"><strong>Eligibility criteria:</strong></p>
    <p>Should have passed 11th standard. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Course duration:</strong></p>
    <p>1 Year. Mid April 2020</p>
    <br>
    <p style="text-decoration: underline;"><strong>Lecture frequency:</strong></p>
    <p>6 weekly sessions of 2.5-3 hour duration. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Tests:</strong></p>
    <p>Prime Monthly Test (PMT) is conducted and students are given ranks based on their relative performance among all batches of Prime. Apart from PMT, regular Unit tests (UT) are also conducted after the completion of every topic. </p>
    <br>
    <p style="text-decoration: underline;"><strong>Doubts:</strong></p>
    <p>Doubts are catered through multiple channels like Prime online portals, whatsapp groups and scheduled doubt session of faculties before or after lectures. </p>
    <br>

    <p><i>Because of COVID-19 Lockdown, lectures are being conducted online. Once things get normal, lectures will be conducted in classrooms in Pune. Those who can not visit Pune and wish to continue online can do so and will be charged 25% less fee.</i></p>

    <p>This course is being conducted in phases. To attend next available demo session, register <a href="https://docs.google.com/forms/d/e/1FAIpQLScM4Tg2q6NmUMoyGwsI_t2nW9zTqPHQdwoa4mB1BavmpnruOA/viewform?usp=sf_link" target="_blank" style="text-decoration: underline; color:blue;"> Here</a></p>

  </div>

</div>

<div id="rank_booster" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Repeaters batch for JEE 2021 </strong></h1>

    <br/>
    <br/>

    <h5>
      If you are not satisfied with your JEE 2020 performance and have a burning zeal to bounce back by reappearing for JEE 2021, then Prime Academy welcomes you to our Repeater’s batches (RP1 & RP2). <br>
      <br>
      <strong>
        For more information about the repeating year and precious tips by previous year repeating batch students, kindly check <a href="https://youtu.be/oURWsNnd4Yo">this link</a>
      </strong>
      <br>
      <br>
      <strong>Faculties:</strong><br>
      Course would be conducted by the brigade of IITians who have been the part of Prime since last decade and have been instrumental in producing one of the best success rates in JEE.<br>
      <br>
      <strong>Commencement of lectures:</strong><br>
      <span style="text-decoration: underline;">Lectures will commence from 7th Oct 2020 @2pm</span>
      <p>Due to pandemic restrictions, lectures would be conducted online. To ensure better faculty-student interaction, there will be one to one interactions as well.</p>
      <p class="pl-4">
        RP1: There will be 2 lectures a day (2 hour per lecture), <br>
        RP2: There will be 2-3 lectures a day (2 hour per lecture).
      </p>
      <br>
      <strong>RP1 vs RP2</strong><br>
      <strong>RP2</strong> batch will be more comprehensive wherein we will discuss fundamentals with a slightly slower pace (as compared to RP1) and take many basic level problems to reinforce better understanding of all the concepts. <strong style="text-decoration: underline;">RP2 batch best suits those students who hadn't gone through any formal coaching in 11th and 12th or scored less than 90%ile in JEE mains 2020</strong>
      <br>
      <br>
      <strong>RP1</strong> will be more of final polishing of concepts where we will discuss the basic concepts at a little higher pace (as compared to RP2 batch) and quickly switch over to problem solving. <strong style="text-decoration: underline;">RP1 batch best suits those students who had scored more than 90%ile in JEE mains 2020.</strong> Even if you couldn't get 90%ile due to exam temperament or some other reasons and are keen to join RP1, you may do so by talking to the course coordinator at Prime.
      <br>
      <br>
      If you feel confident about a few topics and not very confident about others, then it can be a blend of RP1 and RP2 as well. <br>
      <strong>Practice Tests</strong>
      There will be approximately 3 mock tests every month and frequency of tests will increase as the syllabus is about to get over. In the last few weeks, students will take such tests almost daily. It helps to boost their exam skills and temperament multifold.<br>
      <br>
      <br>
      <strong>Course Plan</strong>
      This repeater batch has been customized by considering all the constraints, caused due to delayed JEE 2020.  70% of the syllabus, consisting of most of the core topics will be finished by January 2021. Rest will be catered by march 2021. This bifurcation was important to ensure the better understanding of concepts and give them sufficient time for much needed problem practice.
      <br>
      <br>
      <br>
      <strong>Highlights of last repeater’s batch ( 2019) </strong>
      <ul class="pl-3">
        <li>28 out of 33 repeating students cleared JEE Advanced 2019</li>
        <li>Mrunmayi Bhalerao improved her performance from negative score (in JEE mains 2018) to IIT Computer Science (in 2019 after joining Prime’s Repeaters batch)</li>
      </ul>
      For further details contact <a href="admin@primeacademypune.com">admin@primeacademypune.com</a>
    </h5>
  </div>

</div>

<div id="crash_course" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Crash Course – For JEE Mains and Advanced 2018 </strong></h1>

    <br/>
    <br/>

    <strong>For students</strong>
    who have appeared for 12th but feel the need for professional guidance to see them through engineering entrance exams. Course duration: 2 Months with 6 weekly sessions. Final count-down course with fast track revision by expert IITian faculties stressing on important and high scoring topics. <em>Shortcuts and last moment tried and tested tips just before and during exam.&nbsp;10 mock tests and 100 hr contact program.</em>

    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

</div>

<div id="foundation_course" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Foundation Course for 7th, 8th & 9th appeared students starting June 2017 </strong></h1>

    <br/>
    <br/>

    <strong>Eligibility criteria:</strong> Need to clear Prime Academy’s admission test or personal interview.<em><strong>&nbsp;Course duration:&nbsp;</strong> 9 Months with 2 weekly sessions of 2 hour each.(For details check page 9)</em>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

</div>

<div id="Correspondance_course" class="container mt-5 pb-5" style="display: none; font-family: 'Roman'; background-color: #FFF">

  <div style="padding-top: 30px; padding-left: 10px; padding-right: 10px; font-size: 16px">
    <h1><strong> Correspondence course for IIT & NEET </strong></h1>

    <br/>
    <br/>

    <p>Those students,who cannot join Prime Academy’s classroom course for any reason, may enroll for postal course. In this course we will dispatch the course material which includes course books, assignment sheets, Tutorial sheet and test papers. For doubts such students can take the help of online forum of Prime Academy.</p>
    <p>&nbsp;</p>
    <p>&nbsp;</p>
  </div>

</div> -->
</div>
