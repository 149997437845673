<div class="container">
    <div class="row">
        <div class="col text-center">
           <h1><span class="border-bottom pb-2">Profound Preparatory Insights from D.C. Pandey</span></h1>
        </div>
    </div>
    <div class="row py-4">
        <div class="col-lg-6 p-3">
            <div class="video-container">
                <iframe src="https://www.youtube.com/embed/s-7NjEzZ5D4" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-6 d-flex align-items-center">
            Over the past two decades, the name "D.C. Pandey" has become synonymous with physics excellence in India. His best-selling book has been the go-to guide for budding engineers and doctors alike. Trusting Prime Academy with his own son's education, this physics luminary has now joined our ranks as the faculty and HOD. Dive into his invaluable insights and tips to elevate your preparation, stay motivated, and alleviate the pressures of the journey.
        </div>
    </div>
</div>