import { Injectable } from '@angular/core';

@Injectable({providedIn: 'root'})
export class CourseService {

  constructor() {}
  
  courses = [
    {
      title: 'JEE 2026 - 2 YR COMPREHENSIVE COURSE',
      description:
      'This Course Has Guided 1000s of Students to IIT, NIT, BITS, COEP and Beyond',
      eligibility: 'Must score over 85% in 10th board Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Prime Academy's two-year JEE course is our signature program. Beyond academic excellence, students gain life skills in stress and time management. The extended period makes our academy feel like a second home for many`,
      duration: '2 Years. Till June 2026.',
      frequency: '7-8 sessions weekly, averaging 2.5 hour each',
      coverage:
      'The course is designed for JEE Mains, JEE Advanced, BITSAT,  state entrance exams like MH CET and board exams (CBSE, State & ICSE).',
      test:
      'Prime Mains Test (PMT) and Prime Advanced Test (PAT): Real-exam-like ranking for students across Prime batches. Complemented by Unit Tests (UT) post-topic completion. Approximately 4 tests/month, ensuring sustained engagement and motivation.',
      doubts:
      `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions..`,
      fees: 'INR 3,85,000 (Inclusive of all taxes)',
    },
    {
      title: 'NEET  2026 - 2 YR COMPREHENSIVE COURSE',
      description:
      'This Course covers Physics, Chemistry and Biology to excel in NEET 2026',
      eligibility: 'Must score over 85% in 10th board Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Prime Academy's two-year NEET course not only helps students to clear NEET, but also helps them do good in boards . Beyond academic excellence, students gain life skills in stress and time management. The extended period makes our academy feel like a second home for many`,
      duration: '2 Years. Till June 2026.',
      frequency: '7-8 sessions weekly, averaging 2.5 hour each',
      coverage:
      'The course is designed for NEET and board exams (CBSE, State & ICSE).',
      test:
      'Prime NEET Test (PNT): Real-exam-like ranking for students across Prime batches. Supported by Unit Tests (UT) after each topic. Around 4 tests/month, ensuring engagement and motivation.',
      doubts:
      `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions..`,
      fees: 'INR 3,85,000 (Inclusive of all taxes)',
    },
    {
      title: 'JEE 2025 - 1 YR COMPREHENSIVE COURSE',
      description:
      'This Course Has Guided 1000s of Students to IIT, NIT, BITS, COEP and Beyond',
      eligibility: 'Must score over 85% in 10th board Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Prime Academy's two-year JEE course is our signature program. Beyond academic excellence, students gain life skills in stress and time management. The extended period makes our academy feel like a second home for many`,
      duration: '2 Years. Till June 2025.',
      frequency: '7-8 sessions weekly, averaging 2.5 hour each',
      coverage:
      'The course is designed for JEE Mains, JEE Advanced, BITSAT,  state entrance exams like MH CET and board exams (CBSE, State & ICSE).',
      test: `Prime Mains Test (PMT) and Prime Advanced Test (PAT): Real-exam-like ranking for students across Prime batches. Complemented by Unit Tests (UT) post-topic completion. Approximately 4 tests/month, ensuring sustained engagement and motivation.`,
      doubts:
      `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions.`,
      fees: 'INR 1,93,000 (Inclusive of all taxes)',
    },
    {
      title: 'NEET 2025 - 1 YR COMPREHENSIVE COURSE',
      description:
      'This Course covers Physics, Chemistry and Biology to excel in NEET 2026',
      eligibility: 'Must score over 85% in 10th board Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Prime Academy's two-year NEET course not only helps students to clear NEET, but also helps them do good in boards . Beyond academic excellence, students gain life skills in stress and time management. The extended period makes our academy feel like a second home for many`,
      duration: '2 Years. Till June 2026.',
      frequency: '7-8 sessions weekly, averaging 2.5 hour each',
      coverage:
      'The course is designed for NEET and board exams (CBSE, State & ICSE).',
      test:
      'Prime NEET Test (PNT): Real-exam-like ranking for students across Prime batches. Supported by Unit Tests (UT) after each topic. Around 4 tests/month, ensuring engagement and motivation.',
      doubts: `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions..`,
      fees: 'Rs 1,93,000 (Inclusive of all taxes)',
    },
    {
      title: 'JEE Advanced 2024 - CRASH COURSE',
      description: 'One year and the right guidance can make your JEE 2024 dreams come true. If you are ready to work we pave your path.',
      eligibility: 'Must score over 85% in 10th board Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: 'Designed for those who discovered Prime Academy later, this course compresses two years of learning into one. With personalized guidance, we ensure students are well-prepared to excel in JEE 2024',
      duration: '2 months. Till May 25th, 2024.',
      frequency: '7-8 sessions weekly, averaging 2.5 hour each',
      coverage: 'The course is designed for JEE Mains, JEE Advanced, BITSAT,  state entrance exams like MH CET and board exams (CBSE, State & ICSE).',
      test:`Prime Mains Test (PMT) and Prime Advanced Test (PAT): Real-exam-like ranking for students across Prime batches. Complemented by Unit Tests (UT) post-topic completion. Approximately 4 tests/month, ensuring sustained engagement and motivation.`,
      doubts: `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions.`,
      fees: 'INR 25,000 (Inclusive of all taxes)',
    },
    {
      title: 'Repeaters Course',
      description:
      'Designed for 2024 12th board graduates reattempting JEE in 2025, our rigorous program targets syllabus completion by January 2025',
      eligibility: 'Should have scored over 75% in 12th board or pass Prime Academy’s Entrance Test',
      banner: `For aspirants who've strived in previous JEE attempts and are motivated to invest another year, this opportunity is transformative. This time, you won't struggle with basic fundamentals. Instead, you'll intensely address your weaknesses, conquer once-seemingly impossible problems, and complement your efforts with a series of mock tests, greatly enhancing your scores.`,
      duration: 'Till June 2025',
      frequency: '6-8 sessions weekly, averaging 3 hour each."',
      coverage: 'The course is designed for JEE Mains, JEE Advanced, BITSAT and state entrance exams like MH CET',
      test: `Prime Mains Test (PMT) and Prime Advanced Test (PAT): Real-exam-like ranking for students across Prime batches. Complemented by Unit Tests (UT) post-topic completion. Approximately 4 tests/month, ensuring sustained engagement and motivation.`,
      doubts: `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions.`,
      fees: 'Rs 1,75,000 (Inclusive of all taxes). Students who have cleared JEE Main 2024 will be eligible for 50% Scholarship, Students who have cleared JEE Advanced 2024 will be eligible for a scholarship of INR 135000, Prime Academy students will be eligible for 50% reduction in the final fee',
    },
    {
      title: 'Foundation Course for 8th ',
      description:
      'Unlock the joy of discovery and fortify your intellectual prowess with our Foundation course',
      eligibility: 'Must score over 85% in 7th std Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Master the mathematical and logical reasoning of 8th std topics, and you'll already grasp a major portion of 11th and 12th Maths and Science. Our Foundation course gives you this head start, letting you savor the joy of learning without the weight of exams`,
      duration: 'Till Feb 2025.',
      frequency: '3 sessions weekly, averaging 2 hours each',
      coverage:
      'Encompassing nearly all 8th-grade Math and Science topics from CBSE, ICSE, and State boards. Our approach delves deep, enabling students to solve even 12th-grade problems on the same concepts, moving beyond superficial instructions.',
      test:
      'Monthly one test is conducted based on the last topic covered in lectures',
      doubts:
      `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions.`,
      fees: 'Rs 49,000 (Inclusive of all taxes)',
    },
    {
      title: 'Foundation Course for 9th ',
      description:
      'Unlock the joy of discovery and fortify your intellectual prowess with our Foundation course',
      eligibility: 'Must score over 85% in 8th std Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Master the mathematical and logical reasoning of 9th std topics, and you'll already grasp a major portion of 11th and 12th Maths and Science. Our Foundation course gives you this head start, letting you savor the joy of learning without the weight of exams`,
      duration: 'Till Feb 2025.',
      frequency: '3 sessions weekly, averaging 2 hours each',
      coverage:
      'Encompassing nearly all 9th-grade Math and Science topics from CBSE, ICSE, and State boards. Our approach delves deep, enabling students to solve even 12th-grade problems on the same concepts, moving beyond superficial instructions.',
      test:
      'Monthly one test is conducted based on the last topic covered in lectures',
      doubts:
      `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions.`,
      fees: 'Rs 49,000 (Inclusive of all taxes)',
    },
    {
      title: 'Foundation Course for 10th ',
      description:
      'Unlock the joy of discovery and fortify your intellectual prowess with our Foundation course',
      eligibility: 'Must score over 85% in 9th std Maths and Science or pass Prime Academy’s Entrance Test.',
      banner: `Master the mathematical and logical reasoning of 10th std topics, and you'll already grasp a major portion of 11th and 12th Maths and Science. Our Foundation course gives you this head start, letting you savor the joy of learning without the weight of exams`,
      duration: 'Till Feb 2025.',
      frequency: '3 sessions weekly, averaging 2 hours each',
      coverage:
      'Encompassing nearly all 10th-grade Math and Science topics from CBSE, ICSE, and State boards. Our approach delves deep, enabling students to solve even 12th-grade problems on the same concepts, moving beyond superficial instructions. These students find board exam a cake walk',
      test:
      'Monthly one test is conducted based on the last topic covered in lectures',
      doubts:
      `Despite rigorous efforts and varied mentors, clarifying uncertainties is paramount. At Prime Academy, we're dedicated to resolving doubts through diverse avenues. We urge students to voice questions during lectures. For subsequent inquiries, our Doubt and Difficulty sessions, portal, and student discussions provide resolutions.`,
      fees: 'Rs 49,000 (Inclusive of all taxes)',
    },
  ];
}