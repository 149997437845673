import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Interface } from 'readline';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AnalyticsService } from '../services/analytics.sevice';

interface Result {
  id: Number;
  year: Number;
  exam_type: String;
  name: String;
  rank: String;
}
@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit {
    constructor(private route: ActivatedRoute, private router: Router, private http: HttpClient, private analyticsService: AnalyticsService) {}
  supportedYears = [];
  selectedYear;
  selectedExamType;
  supportedExamType = [
    { id: 'main', name: 'JEE Mains' },
    { id: 'advn', name: 'JEE Advance' },
  ];
  displayResults = {};

  filterdDisplayResults;
  ngOnInit(): void {
    
    this.analyticsService.trackEvent('results', 'results loaded into view', 'VIEW_ENTER')
    this.http.get('https://primeacademypune.s3.ap-south-1.amazonaws.com/results/results.csv', {responseType: 'blob'}).subscribe((result) => {
      result.text().then(text => {
        this.http.get('https://primeacademypune.s3.ap-south-1.amazonaws.com/results/results_config.json', {responseType: 'blob'}).subscribe((resultConfig) => {
          resultConfig.text().then(config => {
            console.log(config);
            const data1 = JSON.parse(config);
            this.supportedYears = data1.supportedYears;
            this.selectedYear = data1.selectedYear;
            this.selectedExamType = data1.selectedExamType;

            const data = text.split('\n');
            for (let row=1; row < data.length; row++){
              const col = data[row].split(',')
              if (col[0] === 'NA'){
                continue;
              }
              this.allResults.push({id: col[0], year: parseInt(col[1]), exam_type: col[2], name: col[3], rank: col[4]})
            }
            console.log(this.allResults);
            this.filter();
            this.route.queryParamMap.subscribe((params) => {
              if (
                !params.get('examType') ||
                !params.get('year') ||
                this.supportedYears.indexOf(Number(params.get('year'))) === -1 ||
                !this.supportedExamType.some(
                  (item) => params.get('examType') == item.id
                )
              ) {
                this.router.navigate([], {
                  relativeTo: this.route,
                  queryParams: {
                    year: this.selectedYear,
                    examType: this.selectedExamType,
                  },
                  queryParamsHandling: 'merge', // remove to replace all query params by provided
                });
              } else {
                this.selectedYear = Number(params.get('year'));
                this.selectedExamType = String(params.get('examType'));
              }
            });
          })
        });
      });
    })
  }
  allResults = [];

  dropDownChanged(event) {
    this.selectedYear = Number(event.split(' ')[1]);
    // this.selectedValue = event.split(' ')[1];
    console.log(event.split(' ')[1]);
    let queryParams = {};
    if (this.displayResults[this.selectedYear][this.selectedExamType].length!=0){
      queryParams = {
        year: this.selectedYear,
        examType: this.selectedExamType,
      };
    }
    else if(this.displayResults[this.selectedYear]['main'].length!=0){
      queryParams = {
        year: this.selectedYear,
        examType: 'main',
      };
    }
    else{
      queryParams = {
        year: this.selectedYear,
        examType: 'advn',
      };
    }
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
  examTypeSelected(event) {
    this.selectedExamType = event;
    console.log(event);
    let queryParams = {
      year: this.selectedYear,
      examType: this.selectedExamType,
    };
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: queryParams,
      queryParamsHandling: 'merge', // remove to replace all query params by provided
    });
  }
  filter() {
    for (const selectedYear of this.supportedYears){
      this.displayResults[selectedYear] = {};
      for (const selectedExamType of this.supportedExamType){
        this.displayResults[selectedYear][selectedExamType.id] = this.allResults.filter((result) => {
          return (
            result.year === selectedYear &&
            result.exam_type === selectedExamType.id
          );
        });
        // .sort((a, b) => Number(a.id) - Number(b.id))
      }
    }
    this.filterdDisplayResults = true;
  }
}
