import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  navFixed: boolean = false;
  private scrollOffset: number = 70;
  title: string;
  @HostListener('window:scroll')
  onWindowScroll() {
    this.navFixed =
      (window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0) > this.scrollOffset;
  }
}
