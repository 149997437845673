import { logging } from 'protractor';
import { Location } from '@angular/common';
import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '../services/analytics.sevice';
import { CourseService } from '../services/course.sevice';

declare let $: any;
interface course {
  title: string;
  description: string;
  eligibility: string;
  duration: string;
  frequency: string;
  coverage: string;
  test: string;
  doubts: string;
  fees: string;
  banner: string;
}
interface QA {
  id: number;
  question: string;
  answer: string;
  answer1?: string;
  answer2?: string;
  answer3?: string;
}

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  courseId: number;
  courses: course[];
  activeCourse: course;
  activeCourseTitle: string;
  QAs: QA[];
  colapse: boolean[] = [false];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private analyticsService: AnalyticsService,
    public courseService: CourseService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.courseId = Number(params['id']);
      this.activeCourse = this.courseService.courses[this.courseId];
      this.activeCourseTitle = this.activeCourse.title;
      this.analyticsService.trackEvent('course', this.activeCourseTitle, 'VIEW_ENTER')

      this.location.go(
        'courses/' +
          this.courseId +
          '/' +
          this.activeCourse?.title
            .toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '')
      );
    });
    this.QAs = [
      {
        id: 0,
        question: 'Do you have any course for 8th / 9th / 10th students?',
        answer:
          'Click here for detailed insight about best study plan for 7th –10th standard students.',
        answer1:
          'We have a foundation course. Foundation program has been initiated for class 8, 9 and 10 students. In this program, a strong foundation is laid to strengthen the concepts in Math and Science. Mental aptitude is also enhanced. The student develops the ability to understand topics at a basic and conceptual level and learns to solve problems in a target oriented approach at a very young age. By the time the student reaches class 11th, he/she develops the self-confidence to compete and get a good rank in competitive exams like IIT-JEE/ NEET . Even an average student can aspire to clear competitive exams like IIT-JEE if he/she joins early and continues in Prime Academy. Besides competitive exams, the Foundation course helps ambitious students prepare early for exams like KVPY, Math/Science/Cyber Olympiads, NDA and also exams at post-graduation level like CAT, IAS, etc.',
        answer2:
          'How shall we come to know of our wards attendance and performance?',
        answer3:
          'Monthly progress report, daily attendance reports are prepared and parents can access those telephonically or online. Parents meetings are conducted to keep parents updated.',
      },
      {
        id: 1,
        question:
          'You should know who will be teaching you once you join the academy',
        answer:
          'At Prime we provide you with the list of the faculties who will be taking your lectures. Sometimes such a list is not even shown in the brochure & on the website!',
      },
      {
        id: 2,
        question: ' Expertise, experience and qualification of Faculty Team',
        answer:
          'The most important criteria to judge the credibility of any educational institute is the competency of the faculty team. Branding and advertising should not affect your decision. Prime Academy has a pool of IITians with many years of experience. (Refer Faculty List, page 3)',
      },
      {
        id: 3,
        question: 'Continuity of Faculty Team',
        answer:
          'Another extremely important factor, which is usually ignored, is the number of years a faculty remains at the institute. If faculties shuffle between many institutes frequently, and sometimes during the academic year, it affects the morale of the student. At Prime the core team has been consistent since the very beginning.',
      },
      {
        id: 4,
        question: 'Availability of faculty team',
        answer:
          'At Prime, the core faculty members are exclusively associated and dedicated to Prime and don’t have any other professional engagements. And hence they understand students better and guide them accordingly. A visiting/part time faculty takes lectures, gives instructions and may disappear and hence can hardly be in touch with students on a regular basis to monitor or guide.',
      },
      {
        id: 5,
        question: 'Number of IIT rankers taught by existing faculty',
        answer:
          'There may be institutes that have a long list of successful candidates in the past which was due to a set of faculty, who may not be working with them any more to teach you. It is basically the faculty team which is responsible for the results achieved by any institute and not the institute’s brand name. At Prime we are proud to claim that our team has 8 such faculty members who are specialists with a high success rate and are highly respected in the entire student community. As a matter of fact, 70% of the students who cleared IIT from Pune in the last 5 years were taught by Prime faculty members.',
      },
      {
        id: 6,
        question: 'Commuting Time & institute infrastructure',
        answer:
          'Long distance travelling not only wastes time in commutation but also drains out a student mentally because of which he can’t concentrate on his studies. Upto a certain extent infrastructure also makes some difference. At Prime we provide students with well lit air-conditioned class rooms with Hi-tech devices like GPRS enabled biometric attendance monitoring & Video conferencing facilities.',
      },
      {
        id: 7,
        question: 'Try to track the best faculties available.',
        answer:
          'There is a set of approximately 15 renowned faculty members who have taught to 95% of students clearing IIT-JEE from Pune. 7 of these top notch faculties are part of Prime Academy since quite a long time and the remaining 8 are distributed among many other institutes. Now the renowned Physics Author Mr D C Pandey has joined this star Team.',
      },
    ];
  }
  ngAfterViewInit() {
    // Add minus icon for collapse element which is open by default
    $('.collapse.show').each(function () {
      $(this)
        .prev('.card-header')
        .find('.fa')
        .addClass('fa-chevron-up')
        .removeClass('fa-chevron-down');
    });

    // Toggle plus minus icon on show hide of collapse element
    $('.collapse')
      .on('show.bs.collapse', function () {
        $(this)
          .prev('.card-header')
          .find('.fa')
          .removeClass('fa-chevron-down')
          .addClass('fa-chevron-up');
      })
      .on('hide.bs.collapse', function () {
        $(this)
          .prev('.card-header')
          .find('.fa')
          .removeClass('fa-chevron-up')
          .addClass('fa-chevron-down');
      });
  }
  onNavigate(index: string) {
    this.activeCourseTitle = this.courseService.courses[index].title;
    index = '/courses/' + index;
    this.router.navigateByUrl(index);
  }
}
