import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.sevice';

@Component({
  selector: 'app-faculty',
  templateUrl: './faculty.component.html',
  styleUrls: ['./faculty.component.scss'],
})
export class FacultyComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService) {}
  facultys = [
    {
      image: '../../assets/faculty/lalit.png',
      name: 'Lalit Kumar',
      univ: 'B. Tech., Electrical Engineering from IIT Bombay',
      desc: 'He has 22 years of IIT-JEE teaching experience and is well known for his smart methods of teaching across the country. He has the distinct achievement of becoming one of the youngest author of IEEE publication in 2001 by presenting his technical paper on ‘Power Distribution and Automation’.'
    },
    {
      image: '../../assets/faculty/dc.png',
      name: 'D C Pandey',
      univ: '',
      desc: 'The renowned Physics Author for IIT JEE and NEET and a faculty with many years of experience.'
    },
    {
      image: '../../assets/faculty/pradeep.png',
      name: 'Pradeep Nagar',
      univ: 'B. Tech. Electrical Engineering from IIT Bombay',
      desc: 'He has 20 years of IIT-JEE teaching experience. He has been DRDO Scientist, Ministry of Defence. One of the greatest motivator who has mastered multiple techniques of solving the toughest of the toughest problems in easiest possible way.'
    },
    {
      image: '../../assets/faculty/preetam.png',
      name: 'Pritam Sen Gupta',
      univ: 'B.Tech from IIT Kharagpur',
      desc: 'He is a very experience teacher with more than 23 years of IIT-JEE teaching experience.'
    },
    {
      image: '../../assets/faculty/rahul.png',
      name: 'Rahul Waware',
      univ: 'B. Tech., Electrical Engineering from IIT Bombay',
      desc: 'He has 22 years of IIT-JEE teaching experience. This calm and composed gentleman knows best, how to use the mind with maximum efficiency and makes sure that every body understands everything without much of stress.'
    },
    {
      image: '../../assets/faculty/nishant.png',
      name: 'Nishant Gaurav',
      univ: 'B.Tech in Chemical Engineering from IIT Kanpur',
      desc: 'He has more than 11 years of IIT JEE Mathematics teaching experience.'
    },
    {
      image: '../../assets/faculty/samprit.png',
      name: 'Samprit Jambhulkar',
      univ: 'B.Tech in Electrical Engineering from IIT Bombay',
      desc: 'He has 3 years of IIT-JEE teaching experience.'
    },
    {
      image: '../../assets/faculty/manoj.png',
      name: 'Manoj Behra',
      univ: 'B.Tech in bio-technology from NIT Roorkee',
      desc: 'He has over 12 years of IIT JEE Chemistry teaching experience.'
    },
    {
      image: '../../assets/faculty/ashish.png',
      name: 'Ashish Anand',
      univ: 'Dual degree from IIT Bombay',
      desc: 'He has 22 years of IIT-JEE teaching experience. He is unarguably one of the best teachers of Organic Chemistry in India.*'
    },
  ];
  ngOnInit(): void {
    this.analyticsService.trackEvent('faculty', 'faculty loaded into view', 'VIEW_ENTER')
  }
}
