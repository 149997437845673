<div class="banner-tabview-wrapper">
  <div class="carousel">
    <owl-carousel-o [options]="customOptions">
      <ng-container *ngFor="let slide of apiData">
        <ng-template class="slide" carouselSlide [id]="slide.id">
          <a [href]="slide.url">
            <img [src]="slide.url" [alt]="slide.title" [title]="slide.title"/>
          </a>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </div>
  <div class="tabview">
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
      <li [ngbNavItem]="1">
        <a ngbNavLink>Announcements</a>
        <ng-template ngbNavContent>
          <div class="p-card-text">
            <div class="p-alert mt-3" *ngFor="let a of announcements">
              {{ a.text }}<br />
              <span *ngIf="a.link"><a href="{{ a.link }}" class="p-alert-link">{{a.linkText}}</a></span>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink>Articles</a>
        <ng-template ngbNavContent>
          <div class="p-card-text">
            <div class="p-alert mt-3" *ngFor="let n of news">
              {{ n.text }}<br /><a href="{{ n.link }}" class="p-alert-link">{{
                n.linkText
              }}</a>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>

    <!-- <pre>Active: {{ active }}</pre> -->
  </div>
</div>

<div class="container-fluid gray-div py-5 choose-institute">
  <div class="video-player px-3">
    <div
      style="margin-bottom: 10%"
      [class.desktop-first]="!(innerWidth < 768)"
      class="title-40"
    >
      How to choose institute?
    </div>
    <iframe
      id="iframe01"
      frameborder="0"
      allow="autoplay; encrypted-media"
      allowfullscreen=""
      src="https://www.youtube.com/embed/xIoMUtRq8b4"
    ></iframe>
  </div>
  <div class="video-details px-3">
    <div [class.desktop-first]="innerWidth < 768" class="title-40">
      How to choose institute?
    </div>
    <div class="desc-1 py-4 px-2">
      <p>
        Choosing the right coaching institute can be a big decision,
        and it's important to focus on the quality and stability of the teaching.
        <span class="desktop-only">
          <br>
          <br>
          Often, students get to know about the quality of teaching only after spending several months in an institute.
          However, this approach carries significant risk and could potentially derail their academic journey if the institute
          falls short of expectations. To avoid this pitfall, it is prudent to pose key questions upfront before
          enrolling: "Who will be teaching me?" Check the teachers' qualifications, their experience, and most importantly,
          their tenure at the institute. Teachers from IIT with years of experience are usually very good, but the institute's
          long-term stability is even more important.
        </span>
      </p>
      <p *ngIf="read_more">
        <br>
        <span class="mobile-only">
          Often, students get to know about the quality of teaching only after spending several months in an institute.
          However, this approach carries significant risk and could potentially derail their academic journey if the institute
          falls short of expectations. To avoid this pitfall, it is prudent to pose key questions upfront before
          enrolling: "Who will be teaching me?" Check the teachers' qualifications, their experience, and most importantly,
          their tenure at the institute. Teachers from IIT with years of experience are usually very good, but the institute's
          long-term stability is even more important.
        </span>
        Also, look at the institute's track record of success, but remember to look beyond the total number of successful
        students. Look at the percentage of students who succeed. High ranks are great, but remember, the teachers who
        helped students achieve those ranks may not be the ones teaching you.
        <br>
        It's crucial to cross-verify all these aspects with students who have already undergone coaching at the particular institute.
        Once you're confident about the quality of the teachers and the institute's track record of success,
        you can generally trust that other components such as the learning materials and test series,
        will be of high quality as most reputable institutes either develop their own content or replicate the best
        available material in the field.
      </p>
    </div>
    <div class="btn-mobile">
      <button class="p-btn-white" (click)="read_more=!read_more">Read <span *ngIf="!read_more">More</span><span *ngIf="read_more">Less</span></button>
    </div>
  </div>
</div>

<div class="container-fluid text-center py-5 prime-facilities">
  <owl-carousel-o [options]="customOptions4">
    <ng-template carouselSlide *ngFor="let feature of features">
      <div class="facilites-wrapper">
        <img alt="{{ feature.title }}" src="{{ feature.img }}" />
        <div class="title-18">{{ feature.title }}</div>
        <p>
          {{feature.desc}}
          <span *ngIf="feature.read_more_link">
            <a href="javascript:void(0)" [routerLink]="feature.read_more_link">Read More</a>
          </span>
          <span *ngIf="feature.read_more_content">
            <span *ngIf="feature.read_more_toggle">
              {{ feature.read_more_content }}
            </span>
            <br>
            <a href="javascript:void(0)" (click)="feature.read_more_toggle=!feature.read_more_toggle">Read <span *ngIf="!feature.read_more_toggle">More</span><span *ngIf="feature.read_more_toggle">Less</span></a>
          </span>
        </p>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

<div class="gray-div text-center py-5">
  <div class="container">
    <div>
      <img src="assets/home/Carousel/BeyondTopRanks.jpeg" class="w-100">
    </div>
  </div>
</div>

<div class="gray-div text-center py-5">
  <div class="title-40">Experience before you Invest!</div>
  <div class="container">
    <div class="row">
      <div class="col">
        <p class="desc-2 py-4 px-2">
          We understand that commiting the future of your child and your hard earned money can be a very difficult decision!<br>
          Prior to joining an institute, it's highly recommended to acquaint yourself with the quality of teaching, operating system, and overall culture.<br>
          Prime Academy offers this opportunity, ensuring utmost transparency in its approach.
          <!-- So come and have a look at how we do things at prime or schedule an appointment to talk with one of our faculty members. Hurry Up! -->
        </p>
      </div>
    </div>
  </div>
  <button class="p-btn-red" routerLink="/forms/common/book_demo">BOOK DEMO</button>
  <button class="p-btn-white mar-32" routerLink="/forms/common/book_appointment">BOOK APPOINTMENT</button>
</div>

<div class="container-fluid text-center py-5" id="happy-students">
  <div class="title-40 mb-5">LET’S HEAR OUR STUDENTS</div>
  <owl-carousel-o [options]="customOptions2">
    <ng-template carouselSlide *ngFor="let t of testimonials; let i = index">
      <section class="testimonial mx-3">
        <header class="clearfix">
          <button class="testimonial__badge">{{ t.rank }}</button>
          <figure class="testimonial__avatar">
            <img alt="1000+ alumni in IIT" [src]="t.studentImageURL" onerror="this.src='assets/home/Testimonial/placeholder.jpg';" />
          </figure>
          <span *ngIf="t.video_url">
            <button
              class="video-play-btn"
              (click)="openTestimonialViewAllOrVideoModal(i, false, true)"
            >
              <!-- <i class="fa fa-play fa-w"></i> -->
            </button>
          </span>
          <p class="testimonial__title">{{ t.name }}</p>
          <p class="testimonial__subtitle">
            {{ t.college }}
          </p>
        </header>
        <p class="testimonial__body">
          {{ t.testimonialDesc | truncate: [275] }}
        </p>
        <nav class="testimonial__nav">
          <a
            href="javascript:void(0)"
            (click)="openTestimonialViewAllOrVideoModal(i, true, false)"
            >READ MORE <i class="fa fa-chevron-right"> </i>
          </a>
        </nav>
      </section>
    </ng-template>
  </owl-carousel-o>
  <button class="p-btn-red mt-5" routerLink="testimonials">VIEW ALL</button>
</div>

<div id="programs" class="container-fluid text-center py-5">
  <div class="title-44">OUR PROGRAMS</div>
  <div class="row pb-4 m-3 pcards">
    <div class="pcard col-md-3 pt-4" *ngFor="let p of courseService.courses; let index=index">
      <div class="p-card pb-2">
        <div class="p-card-title">
          {{ p.title }}
        </div>
        <div class="p-card-text">
          {{ p.coverage }}
        </div>
        <div class="text-center">
          <a href="javascript:void(0)" [routerLink]="'/courses/' + index">
            <div class="view-details view-details-red pt-2">
              VIEW DETAILS
            </div>
          </a>
        </div>
          <!-- <div class="col-md-6 col-sm-6 text-left w-50">
            <a href="#">
              <div class="view-details view-details-red pt-2">REGISTER</div>
            </a>
          </div> -->
      </div>
    </div>
  </div>
  <div class="btn-mobile">
    <button class="p-btn-red" routerLink="/courses">VIEW ALL PROGRAMS</button>
    <button class="p-btn-white mar-32" routerLink="/forms/common/send_inquiry">SEND INQUIRY</button>
  </div>
</div>

<div class="container-fluid text-center py-5 prime-media">
  <div class="title-44">Spotlight on Prime Academy: Making Waves in Media.</div>
  <div class="desc-gray">
    Despite refraining from conventional marketing, Prime Academy consistently graces headlines each year.<br>
    From garnering commendations from honorable HRD minister for its remarkable success rates to extending 
    free coaching to underprivileged students, the institution shines.
    <br class="desktop-only" />
    Noteworthy achievements include students clinching Miss India titles and representing India with pride on international educational platforms.<br>
    Prime Academy stands as a testament to diverse accomplishments, etching its mark in the media landscape.
  </div>
  <owl-carousel-o [options]="customOptions1">
    <ng-template carouselSlide *ngFor="let t of media; let i = index">
      <div
        class="slide"
        (click)="openTestimonialViewAllOrVideoModal(i, false, false, true)"
      >
        <div
          class="bg-image"
          style="background-image: url('{{ t.thumbnail }}')"
        >
          <!-- <div class="text">
            <div>400 students cleared</div>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam
              neque diam, accumsan sit amet enim eget.
            </p>
          </div> -->
        </div>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

<div class="blue-div text-center py-5 px-2 prime-learning">
  <div class="title-white-40">IT’S TIME TO START LEARNING WITH US!</div>
  <!-- <div class="desc-2 py-4 px-2">
    Hurry up and register now
    <br class="desktop-only" />
    amet enim eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
    Aliquam neque diam, <br class="desktop-only" />
    accumsan sit amet enim eget.
  </div> -->
  <button class="p-btn-red mt-5" routerLink="/forms/common/register_now">REGISTER NOW</button>
</div>

<div class="container-fluid text-center py-5 prime-gallery">
  <div class="title-44 my-4">OUR GALLERY</div>
  <owl-carousel-o [options]="customOptions3">
    <ng-template carouselSlide *ngFor="let t of gallery">
      <div class="slide">
        <a [href]="t.path">
          <div class="bg-image" [style.background-image]="'url('+t.thumbnail+')'"></div>
        </a>
      </div>
    </ng-template>
  </owl-carousel-o>
</div>

<!-- <div class="container" style="text-align: center">
  <p style="text-align: center; font-size: 32px">
    <span class="mb-4"
      ><a href="/contact_us" style="font-size: 20px; color: white"
        >Contact Us</a
      ></span
    >
  </p>

  <div class="row text-light">
    <div class="col-md-4 mb-2">
      <div
        class="pb-2"
        style="
          padding-top: 30px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        "
      >
        <a
          href="https://www.google.com/maps/dir//Prime+Academy+IIT-+JEE,+NEET+,NDA+9th+%26+10th+coaching+class.,+3rd+floor+,+Behind+Bank+of+India,+Ketki+Chambers,+Near+Alka+Talkis,+Deccan,+Sadashiv+Peth,+Pune,+Maharashtra+411030/@18.8357653,72.8167501,9z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c04fa20ed165:0x559a3a30a7297dce!2m2!1d73.8430071!2d18.5122139"
          ><img src="/assets/footer/maps/deccan-prime.png" width="70%"
        /></a>

        <h3 style="text-decoration: underline">
          <a
            href="https://www.google.com/maps/dir//Prime+Academy+IIT-+JEE,+NEET+,NDA+9th+%26+10th+coaching+class.,+3rd+floor+,+Behind+Bank+of+India,+Ketki+Chambers,+Near+Alka+Talkis,+Deccan,+Sadashiv+Peth,+Pune,+Maharashtra+411030/@18.8357653,72.8167501,9z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c04fa20ed165:0x559a3a30a7297dce!2m2!1d73.8430071!2d18.5122139"
            >Deccan (Head Office)</a
          >
        </h3>

        <p>
          Office No. 505, 3rd Floor, Ketki Building, Behind Bank of India, Near
          Alka Talkies, Sadashivpeth, Pune- 411030. Tel:&nbsp;<strong
            >9021850095</strong
          >
          Mob:&nbsp;<strong>+91 9372267644</strong>
        </p>
      </div>
    </div>

    <div class="col-md-4 mb-2">
      <div
        class="pb-2"
        style="
          padding-top: 30px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        "
      >
        <a
          href="https://www.google.com/maps/dir//Prime+Academy+IIT+-JEE+,+NEET+,+10th+%26+9th+Coaching+class,+Viman+Nagar+Office+No.+307,+Tradenet+Building,+Above+Maruti+Showroom+411014,Nag,+Pune,+Maharashtra+411006/@18.8571982,72.8537547,9z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c14165cbdb31:0xb0a162bb51e6aa70!2m2!1d73.9178411!2d18.5611383"
          ><img src="/assets/footer/maps/viman-nagar-prime.png" width="70%"
        /></a>

        <h3 style="text-decoration: underline">
          <a
            href="https://www.google.com/maps/dir//Prime+Academy+IIT+-JEE+,+NEET+,+10th+%26+9th+Coaching+class,+Viman+Nagar+Office+No.+307,+Tradenet+Building,+Above+Maruti+Showroom+411014,Nag,+Pune,+Maharashtra+411006/@18.8571982,72.8537547,9z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2c14165cbdb31:0xb0a162bb51e6aa70!2m2!1d73.9178411!2d18.5611383"
            >Vimannagar</a
          >
        </h3>
        <p>
          Off No. – 307, Tradenet Building, Above Maruti Showroom, Vimannagar
          Tel:<strong>&nbsp;8793032998</strong> Tel:<strong
            >&nbsp;9595520089</strong
          >
        </p>
      </div>
    </div>

    <div class="col-md-4 mb-2">
      <div
        class="pb-2"
        style="
          padding-top: 30px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        "
      >
        <a
          href="https://www.google.com/maps/dir//Prime+Academy+IIT-JEE,+NEET,+Foundation+classs,+Office+No.+5,+3rd+Floor,+Satyam+Apartment,+Adjacent+to+Station,+Station+Rd,+Pimpri+Colony,+Pune,+Maharashtra+411018/@18.8943091,72.7963922,9z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2b856cd2ec6e9:0xa86f181d24a7453a!2m2!1d73.8027795!2d18.6230915"
          ><img src="/assets/footer/maps/pimpri-prime.png" width="70%"
        /></a>

        <h3 style="text-decoration: underline">
          <a
            href="https://www.google.com/maps/dir//Prime+Academy+IIT-JEE,+NEET,+Foundation+classs,+Office+No.+5,+3rd+Floor,+Satyam+Apartment,+Adjacent+to+Station,+Station+Rd,+Pimpri+Colony,+Pune,+Maharashtra+411018/@18.8943091,72.7963922,9z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bc2b856cd2ec6e9:0xa86f181d24a7453a!2m2!1d73.8027795!2d18.6230915"
            >Pimpri</a
          >
        </h3>
        <p>
          Off No. – 5, 3rd Floor, Satyam Apartment, Adjacent to Pimpri Station
          Tel:&nbsp;<strong>8055340090</strong>
        </p>
      </div>
    </div>
  </div>

  <div class="row text-light">
    <div class="col-md-2"></div>
    <div class="col-md-4">
      <div
        class="pb-2"
        style="
          padding-top: 30px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        "
      >
        <a
          href="https://www.google.com/maps/dir//Luthra+Chambers,+College+Road,+Nashik,+Maharashtra/@20.0069771,73.7243674,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bddeb84d508a03f:0x1f5cd686955ceec4!2m2!1d73.7593869!2d20.0068999"
          ><img src="/assets/footer/maps/haddapsar-prime.png" width="70%"
        /></a>

        <h3 style="text-decoration: underline">
          <a
            href="https://www.google.com/maps/dir//Luthra+Chambers,+College+Road,+Nashik,+Maharashtra/@20.0069771,73.7243674,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x3bddeb84d508a03f:0x1f5cd686955ceec4!2m2!1d73.7593869!2d20.0068999"
            >Hadapsar</a
          >
        </h3>
        <p>
          Prime Academy, Office 202, Vishal Ventila, Behind Nobel hospital,
          Hadapsar Pune 411013, Tel:&nbsp;<strong>9595040094</strong>
        </p>
      </div>
    </div>
    <div class="col-md-4">
      <div
        class="pb-2"
        style="
          padding-top: 30px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
        "
      >
        <a
          href="https://www.google.com/maps/dir//Bramha+Majestic,+NIBM+Rd,+NIBM+Undri+Road,+Kausar+Baugh,+Kondhwa,+Pune,+Maharashtra+411048/@18.4766174,73.859951,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x3bc2eb4d47038ccb:0xd0e7f57b052d97a3!2m2!1d73.8950565!2d18.4766206"
          ><img src="/assets/footer/maps/wanawadi-prime.png" width="70%"
        /></a>

        <h3 style="text-decoration: underline">
          <a
            href="https://www.google.com/maps/dir//Bramha+Majestic,+NIBM+Rd,+NIBM+Undri+Road,+Kausar+Baugh,+Kondhwa,+Pune,+Maharashtra+411048/@18.4766174,73.859951,13z/data=!3m1!4b1!4m8!4m7!1m0!1m5!1m1!1s0x3bc2eb4d47038ccb:0xd0e7f57b052d97a3!2m2!1d73.8950565!2d18.4766206"
            >Wanawadi</a
          >
        </h3>
        <p>
          Building No. C-3, Office No.301, Brahma Majestic, NIBM Road, Kondhwa,
          Pune- 411048 Tel:<strong>&nbsp;020 – 4130 7590</strong>
          Mob:&nbsp;<strong> 9371663143 </strong>
        </p>
      </div>
    </div>
    <div class="col-md-2"></div>
  </div>
</div> -->
