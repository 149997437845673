import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.sevice';

@Component({
  selector: 'app-profound-preparatory-insights-from-dc-pandey',
  templateUrl: './profound-preparatory-insights-from-dc-pandey.component.html',
  styleUrls: ['./profound-preparatory-insights-from-dc-pandey.component.scss']
})
export class ProfoundPreparatoryInsightsFromDcPandeyComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('blog-profound-preparatory-insights-from-dc-pandey', 'blog-profound-preparatory-insights-from-dc-pandey view loaded', 'VIEW_ENTER')
  }
}
