import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {

  constructor() { }
  notifications = [
    '25% students cracked JEE Advanced 2023.', 
    '70% students cleared JEE Mains 2023.',
    'In the past 15 years, 2,628 out of 3,125 Prime Academy students cleared the JEE Mains, with 1,017 cracking JEE Advanced'
  ];
  ngOnInit(): void {
  }

}
