<article id="meet_team">
  <div class="faculty__white_bg py-2">
    <header class="faculty__header mt-4">FAQs!</header>
    <!-- <div class="faculty__desc m-2 px-5">
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam neque
      diam, accumsan sit amet enim eget. Lorem ipsum dolor siteget. Lorem ipsum
      dolor sit amet consectetur adipisicing elit. Tempora, assumenda.
    </div> -->
    <div class="container py-1 pb-3 input-icons text-end">
      <span class="input-float-right">
        <i class="fa fa-search icon" style="font-size: 30px;"></i>
        <input type="text" class="text-end input-field search-style"  (input)="myFunction()" (keyup)="keyup_func()" (keydown)="keydown_func()" #search>
      </span>
    </div>
  </div>
  <div class="container facultys pb-4">
    <app-faqs-accordion [options]="options" [menus]="visibleMenus">
    </app-faqs-accordion>
  </div>
</article>
