import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from '../services/analytics.sevice';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss'],
})
export class BlogsComponent implements OnInit {
  constructor(private analyticsService: AnalyticsService) {}
  blogs = [
    {
      title: 'How to prepare and get good score in board exam?',
      url: 'https://tipsbylalitkumar.blogspot.in/',
      external_url: true,
    },
    {
      title:
        'How to do 7th-10th standard studies effectively & Advantages of Foundation courses',
      url: 'https://foundationtipsbylalitkumar.blogspot.in',
      external_url: true,
    },
    {
      title: 'How to choose a coaching class for IIT JEE?',
      url: 'https://bestiitjeecoaching.blogspot.in/',
      external_url: true,
    },
    {
      title: 'How to prepare for JEE?',
      url: 'https://a2zofiitjee.blogspot.in/',
      external_url: true,
    },
    {
      title: 'Crucial Insights for JEE/NEET Aspirants',
      url: '/blogs/crucial-insights-for-jee-neet-aspirants',
      external_url: false,
    },
    {
      title: 'Know How to Choose a Coaching Class',
      url: '/blogs/know-how-to-choose-a-coaching-class',
      external_url: false,
    },
    {
      title: 'Profound Preparatory Insights from DC Pandey',
      url: '/blogs/profound-preparatory-insights-from-dc-pandey',
      external_url: false,
    },
    {
      title: 'Checkout our Stable Prime Faculty',
      url: '/blogs/read-more-stable-prime-faculty',
      external_url: false,
    },
    {
      title: 'Students Looking for Re-Attempting JEE/NEET',
      url: '/blogs/students-looking-for-reattempting-jee-neet',
      external_url: false,
    },
    {
      title: 'Unlock Success with IIT JEE Top Scorers',
      url: '/blogs/unlock-success-with-iit-jee-top-scorers',
      external_url: false,
    },
  ];
  ngOnInit(): void {
    this.analyticsService.trackEvent('blog_home_page', 'blog_home_page view loaded', 'VIEW_ENTER')
  }
  public openNewTab(url) {
    window.open(url, '_blank');
  }
}
