<div class="container">
    <div class="row">
        <div class="col text-center">
           <h1><span class="border-bottom pb-2">Crucial Insights for JEE/NEET Aspirants</span></h1>
        </div>
    </div>
    <div class="row py-4">
        <div class="col-lg-6 p-3">
            <div class="video-container">
                <iframe src="https://www.youtube.com/embed/tFMop6woxxg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-6  d-flex align-items-center">
            It's essential for both students and parents to be aware of key strategies that can boost preparation efficiency.
            Often, students recognize these insights later in their preparation journey.
            This video delves into these pivotal tips that can simplify your learning process and substantially elevate your scores in competitive exams like JEE and NEET.
            Countless Prime Academy students have already reaped the benefits of these valuable suggestions.
        </div>
    </div>
</div>