import { Component, OnInit } from '@angular/core';
import { AnalyticsService } from 'src/app/services/analytics.sevice';

@Component({
  selector: 'app-know-how-to-choose-a-coaching-class',
  templateUrl: './know-how-to-choose-a-coaching-class.component.html',
  styleUrls: ['./know-how-to-choose-a-coaching-class.component.scss']
})
export class KnowHowToChooseACoachingClassComponent implements OnInit {

  constructor(private analyticsService: AnalyticsService) { }

  ngOnInit(): void {
    this.analyticsService.trackEvent('blog-know-how-to-choose-a-coaching-class', 'blog-know-how-to-choose-a-coaching-class view loaded', 'VIEW_ENTER')
  }
}
