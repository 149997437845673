import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SafePipe } from 'src/app/services/safe-pipe.pipe';
export interface Testimonial {
  name: string;
  studentImageURL: string;
  rank: string;
  college: string;
  testimonialDesc: string;
}
export interface Media {
  id: number;
  thumbnail: string;
  preview: string;
}
@Component({
  selector: 'app-popup-modal',
  templateUrl: './popup-modal.component.html',
  styleUrls: ['./popup-modal.component.scss'],
})
export class PopupModalComponent implements OnInit {
  constructor(public bsModalRef: BsModalRef, public safe: SafePipe) {}
  isTestimonialViewAllView: boolean;
  video_url: string;
  isTestimonialVideoView: boolean;
  testimonialItem: Testimonial;
  testimonialItems: Testimonial[];
  currentIndex: number;
  isMedia: boolean;
  medias: Media[];

  ngOnInit(): void {}
  showVideo() {
    this.isTestimonialViewAllView = false;
    this.isTestimonialVideoView = true;
  }
  goToViewAll() {
    if (!this.isTestimonialViewAllView) {
      this.isTestimonialViewAllView = true;
      this.isTestimonialVideoView = false;
    }
  }
  slideNext() {
    ++this.currentIndex;
  }
  slidePrev() {
    this.currentIndex--;
  }
}
