<article class="blogs faculty__white_bg py-2">
  <header>
    <h1 class="blogs__header mt-4">OUR BLOGS</h1>
  </header>
  <div class="blogs__desc m-2 px-5">
    <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam neque diam,
    accumsan sit amet enim eget. Lorem ipsum dolor siteget. Lorem ipsum dolor
    sit amet consectetur adipisicing elit. Tempora, assumenda. -->
  </div>
  <div class="container blogs">
    <div class="row py-5">
      <div
        class="col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-4"
        *ngFor="let item of blogs"
      >
        <span *ngIf="item.external_url">
          <a [href]="item.url" target="_blank">
            <div class="slide">
              <p>
                {{ item.title }}
              </p>
            </div>
          </a>
        </span>
        <span *ngIf="!item.external_url">
          <a href="javascript:void(0)" [routerLink]="item.url">
            <div class="slide">
              <p>
                {{ item.title }}
              </p>
            </div>
          </a>
        </span>
      </div>
    </div>
  </div>
</article>
<aside class="blogs__quote">
  <div class="blogs__left-quote">
    <img
      src="../../assets/images/static/inverted-red-left.png"
      alt="start quote"
    />
  </div>
  <p>
    Perfection is not attainable, but if we chase perfection we can catch
    excellence.
  </p>
  <div class="blogs__right-quote">
    <img
      src="../../assets/images/static/inverted-red-right.png"
      alt="end quote"
    />
  </div>
</aside>
