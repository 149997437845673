<div class="modal-header popup-modal-header">
  <button
    [ngClass]="
      isTestimonialViewAllView
        ? 'testimonial__badge'
        : 'popup-modal-back-button'
    "
    *ngIf="!isMedia"
    class="mx-3"
    (click)="goToViewAll()"
  >
    {{ isTestimonialViewAllView ? testimonialItem.rank : "" }}
  </button>
  <button
    type="button"
    class="close pull-right mt-1"
    aria-label="Close"
    (click)="bsModalRef.hide()"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <section class="mx-3" *ngIf="isTestimonialViewAllView">
    <header class="clearfix popup-modal-body-header half-bottom-border">
      <figure class="testimonial__avatar">
        <img
          alt="1000+ alumni in IIT"
          [src]="testimonialItems[currentIndex].studentImageURL"
        />
      </figure>
      <button *ngIf="testimonialItem.video_url" class="video-play-btn" (click)="showVideo()">
        <!-- <i class="fa fa-play fa-w"></i> -->
      </button>
      <p class="testimonial__title">
        {{ testimonialItems[currentIndex].name }}
      </p>
      <p class="testimonial__subtitle">
        {{ testimonialItems[currentIndex].college }}
      </p>
    </header>
    <p class="testimonial_popup__body my-3" [innerHTML]="testimonialItems[currentIndex].testimonialDesc">
    </p>
    <div
      class="pop-up-nav-btn pop-up-next-slide"
      *ngIf="currentIndex < testimonialItems.length - 1"
      (click)="slideNext()"
    ></div>
    <div
      class="pop-up-nav-btn pop-up-prev-slide"
      *ngIf="currentIndex != 0"
      (click)="slidePrev()"
    ></div>
  </section>
  <section *ngIf="isTestimonialVideoView">
    <div class="video-player">
      <iframe [src]="testimonialItem.video_url | safe:'url'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
  </section>
  <section *ngIf="isMedia">
    <div class="container">
      <div class="row">
        <div class="pop-up-media col-md-12 col-sm-12 col-lg-12">
          <figure>
            <img src="{{ medias[currentIndex].preview }}" alt="Media Images" />
          </figure>
        </div>
      </div>
    </div>
    <div
      class="pop-up-nav-btn pop-up-next-slide"
      *ngIf="currentIndex < medias.length - 1"
      (click)="slideNext()"
    ></div>
    <div
      class="pop-up-nav-btn pop-up-prev-slide"
      *ngIf="currentIndex != 0"
      (click)="slidePrev()"
    ></div>
  </section>
</div>
