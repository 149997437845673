import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { NgbModule, NgbCarouselModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { RouterModule } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { CoursesComponent } from './courses/courses.component';
import { FooterComponent } from './footer/footer.component';
import { NotificationBarComponent } from './notification-bar/notification-bar.component';
import { FacultyComponent } from './faculty/faculty.component';
import { FormsModule } from '@angular/forms';
import { ResultsComponent } from './results/results.component';
import { BlogsComponent } from './blogs/blogs.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopupModalComponent } from './popup-modal/popup-modal.component';
import { HttpClientModule } from '@angular/common/http';

import { TruncatePipe } from './truncate.pipe';
import { FaqsComponent } from './faqs/faqs.component';
import { FaqsAccordionComponent } from './faqs-accordion/faqs-accordion.component';
import { ReadMoreStablePrimeFacultyComponent } from './blogs/read-more-stable-prime-faculty/read-more-stable-prime-faculty.component';
import { CrucialInsightsForJeeNeetAspirantsComponent } from './blogs/crucial-insights-for-jee-neet-aspirants/crucial-insights-for-jee-neet-aspirants.component';
import { UnlockSuccessWithIitJeeTopScorersComponent } from './blogs/unlock-success-with-iit-jee-top-scorers/unlock-success-with-iit-jee-top-scorers.component';
import { ProfoundPreparatoryInsightsFromDcPandeyComponent } from './blogs/profound-preparatory-insights-from-dc-pandey/profound-preparatory-insights-from-dc-pandey.component';
import { KnowHowToChooseACoachingClassComponent } from './blogs/know-how-to-choose-a-coaching-class/know-how-to-choose-a-coaching-class.component';
import { StudentsLookingForReattemptingJeeNeetComponent } from './blogs/students-looking-for-reattempting-jee-neet/students-looking-for-reattempting-jee-neet.component';
import { SafePipe } from './services/safe-pipe.pipe';
import { FormsComponent } from './forms/forms.component';
import { CommonComponent } from './forms/common/common.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FreeBridgeCourseComponent } from './forms/free-bridge-course/free-bridge-course.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    AboutUsComponent,
    TestimonialsComponent,
    CoursesComponent,
    FooterComponent,
    NotificationBarComponent,
    FacultyComponent,
    ResultsComponent,
    BlogsComponent,
    PopupModalComponent,
    TruncatePipe,
    FaqsComponent,
    FaqsAccordionComponent,
    ReadMoreStablePrimeFacultyComponent,
    CrucialInsightsForJeeNeetAspirantsComponent,
    UnlockSuccessWithIitJeeTopScorersComponent,
    ProfoundPreparatoryInsightsFromDcPandeyComponent,
    KnowHowToChooseACoachingClassComponent,
    StudentsLookingForReattemptingJeeNeetComponent,
    SafePipe,
    FormsComponent,
    CommonComponent,
    FreeBridgeCourseComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CarouselModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    MatSnackBarModule,
    HttpClientModule,
    ModalModule.forRoot(),
    RouterModule.forRoot(
      [
        { path: 'about', component: AboutUsComponent },
        { path: 'testimonials', component: TestimonialsComponent },
        { path: 'courses', redirectTo: 'courses/0', pathMatch: 'full' },
        { path: 'courses/:id', component: CoursesComponent },
        { path: 'courses/:id/:slug', component: CoursesComponent },
        { path: '', component: HomeComponent },
        { path: 'faculty', component: FacultyComponent },
        { path: 'results', component: ResultsComponent },
        { path: 'blogs', component: BlogsComponent },
        { path: 'blogs/read-more-stable-prime-faculty', component: ReadMoreStablePrimeFacultyComponent },
        { path: 'blogs/crucial-insights-for-jee-neet-aspirants', component: CrucialInsightsForJeeNeetAspirantsComponent },
        { path: 'blogs/unlock-success-with-iit-jee-top-scorers', component: UnlockSuccessWithIitJeeTopScorersComponent },
        { path: 'blogs/profound-preparatory-insights-from-dc-pandey', component: ProfoundPreparatoryInsightsFromDcPandeyComponent },
        { path: 'blogs/know-how-to-choose-a-coaching-class', component: KnowHowToChooseACoachingClassComponent },
        { path: 'blogs/students-looking-for-reattempting-jee-neet', component: StudentsLookingForReattemptingJeeNeetComponent },
        { path: 'contact_us', component: HomeComponent },
        { path: 'faqs', component: FaqsComponent },
        { path: 'forms/common/:wherefrom', component: CommonComponent},
        { path: 'forms/free_bridge_course', component: FreeBridgeCourseComponent}
      ],
      {
        scrollPositionRestoration: 'enabled', // or 'top'
        anchorScrolling: 'enabled',
        scrollOffset: [0, 64],
      }
    ),
    NgbCarouselModule,
  ],
  exports: [SafePipe],
  providers: [SafePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
