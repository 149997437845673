import { Component, OnInit, Renderer2 } from '@angular/core';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {}

  goToFooter() {
    try {
      const errorField = this.renderer.selectRootElement('.footer-end');
      errorField.scrollIntoView();
    } catch (err) {
      console.log(err);
    }
  }
}
