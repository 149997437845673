<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <button
    style="outline: none"
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <a class="navbar-brand" routerLink="/" style="max-width: 69%"
    ><img src="/assets/logo.png" style="max-width: 100%"
  /></a>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/"
          routerLinkActive="active"
          >Home</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/about"
          routerLinkActive="active"
          >About Us</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/courses"
          routerLinkActive="active"
          >Courses</a
        >
      </li>

      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/results"
          routerLinkActive="active"
          >Results</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/testimonials"
          routerLinkActive="active"
          >Testimonials</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/faculty"
          routerLinkActive="active"
          >Faculty</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/faqs"
          routerLinkActive="active"
          >FAQs</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLink="/blogs"
          routerLinkActive="active"
          >Blogs</a
        >
      </li>
      <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          class="nav-link"
          href="javascript:void(0)"
          routerLinkActive="active"
          (click)="goToFooter()"
          >Contact Us</a
        >
      </li>
      <!-- <li
        class="nav-item"
        data-toggle="collapse"
        data-target=".navbar-collapse.show"
      >
        <a
          style="color: #d50000 !important"
          class="nav-link"
          href="https://online.primeacademypune.com/"
          routerLinkActive="active"
          target="_blank"
          >Online Portal</a
        >
      </li> -->
    </ul>
  </div>
</nav>
<app-notification-bar></app-notification-bar>
