<article class="testimonials container">
  <header>
    <h1 class="testimonials__header">LET’S HEAR OUR STUDENTS</h1>
  </header>
  <div class="row">
    <div
      class="col-xl-4 col-lg-4 col-md-6 col-sm-12"
      *ngFor="let item of testimonials; let i = index"
    >
      <section class="testimonial my-3">
        <header class="clearfix">
          <button class="testimonial__badge">{{ item.rank }}</button>
          <figure class="testimonial__avatar">
            <img alt="1000+ alumni in IIT" src="{{ item.studentImageURL }}" onerror="this.src='../../assets/home/Testimonial/placeholder.jpg';" />
          </figure>
          <span *ngIf="item.video_url">
            <button
              class="video-play-btn"
              (click)="openTestimonialViewAllOrVideoModal(i, false, true)"
            >
              <!-- <i class="fa fa-play fa-w"></i> -->
            </button>
          </span>
          <p class="testimonial__title">{{ item.name }}</p>
          <p class="testimonial__subtitle">{{ item.college }}</p>
        </header>
        <p class="testimonial__body" *ngIf="i < 9" [innerHTML]="item.testimonialDesc | truncate: [300]">
        </p>
        <nav class="testimonial__nav">
          <a *ngIf="i < 9"
            href="javascript:void(0)"
            (click)="openTestimonialViewAllOrVideoModal(i, true, false)"
            >READ MORE <i class="fa fa-chevron-right"> </i>
          </a>
          <a *ngIf="i >= 9"
            href="javascript:void(0)"
            (click)="openTestimonialViewAllOrVideoModal(i, true, false)"
            >READ <i class="fa fa-chevron-right"> </i>
          </a>
        </nav>
      </section>
    </div>
  </div>
  <aside class="testimonial__quote">
    <div class="testimonial__left-quote">
      <img
        src="../../assets/images/static/inverted-blue-left.png"
        alt="start quote"
      />
    </div>
    <p>
      Perfection is not attainable, but if we chase perfection we can catch
      excellence.
    </p>
    <div class="testimonial__right-quote">
      <img
        src="../../assets/images/static/inverted-blue-right.png"
        alt="end quote"
      />
    </div>
  </aside>
</article>
