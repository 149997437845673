import { Component, OnInit, HostListener } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { PopupModalComponent } from '../popup-modal/popup-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { HttpClient } from '@angular/common/http';
import { AnalyticsService } from '../services/analytics.sevice'
import { CourseService } from '../services/course.sevice';
// import { DeviceDetectorService } from 'ngx-device-detector';

export interface PhotosApi {
  albumId?: number;
  id?: string;
  title?: string;
  url?: string;
  thumbnailUrl?: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  apiData: PhotosApi[];
  active = 1;


  customOptions: OwlOptions = {
    loop: false,
    autoplay: false,
    // center: true,
    // center: true,
    dots: true,
    navText: [
      '<i class="fa-chevron-left"></i>',
      '<i class="fa-chevron-right></i>"',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  read_more=false;

  customOptions1: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      940: {
        items: 2,
      },
    },
    nav: true,
  };

  customOptions2: OwlOptions = {
    animateOut: 'fadeOut',
    loop: true,
    freeDrag: true,
    lazyLoad: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    autoplayHoverPause: true,
    dots: true,
    smartSpeed: 2000,
    animateIn: true,
    // navText: [
    //   "<div class='nav-btn prev-slide'></div>",
    //   "<div class='nav-btn next-slide'></div>",
    // ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
    nav: true,
  };

  customOptions3: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: [
      "<div class='nav-btn prev-slide'></div>",
      "<div class='nav-btn next-slide'></div>",
    ],
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 2,
      },
      600: {
        items: 3,
      },
      900: {
        items: 4,
      },
    },
    nav: true,
  };
  customOptions4: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 2,
      },
      740: {
        items: 3,
      },
      940: {
        items: 4,
      },
    },
    nav: true,
  };

  // announcements = [
  //   {
  //     text:
  //       'Outstanding Outcome: Witness 25% soar in JEE Advanced 2023, with 70% conquering JEE Mains!',
  //     linkText: 'READ MORE',
  //     link: 'https://www.punekarnews.in/remarkable-jee-advanced-2023-results-at-prime-academy-pune-over-75-success-in-jee-mains-and-27-students-cracked-jee-advanced/',
  //   },
  //   {
  //     text:
  //       'JEE/NEET 2025 Readiness: Enroll in Prime’s course, perfect for strengthening 11th std fundamentals.',
  //     linkText: 'VISIT PROGRAM DETAILS',
  //   },
  //   {
  //     text:
  //       'Embrace STEM Passion: Unveil Prime Academy’s foundation course, kindling love for Math & Science (Grades 8-10).',
  //     linkText: 'VISIT PROGRAM DETAILS',
  //     link: '',
  //   },
  //   {
  //     text:
  //       'Empowering the Underprivileged: Unlock free JEE/NEET prep at Prime for aspiring students in need.',
  //     linkText: 'VISIT PROGRAM DETAILS',
  //     link: '',
  //   }
  // ];
  news = [
    {
      text: 'Crucial Insights for JEE/NEET Aspirants',
      linkText: 'READ NOW',
      link: '/blogs/crucial-insights-for-jee-neet-aspirants'
    },
    {
      text: 'Unlock Success with IIT JEE Top Scorers',
      linkText: 'READ NOW',
      link: '/blogs/unlock-success-with-iit-jee-top-scorers'
    },
    {
      text: 'Profound Preparatory Insights from D.C. Pandey',
      linkText: 'READ NOW',
      link: '/blogs/profound-preparatory-insights-from-dc-pandey'
    },
    {
      text: 'Know How To Choose A Coaching Class?',
      linkText: 'READ NOW',
      link: '/blogs/know-how-to-choose-a-coaching-class'
    },
    {
      text: "Revamping for Victory: The Repeater's Batch Journey",
      linkText: 'READ NOW',
      link: '/blogs/students-looking-for-reattempting-jee-neet'
    },


    {
      text: 'Remarkable JEE Advanced 2023 Results at Prime Academy',
      linkText: 'READ NOW',
      link: 'https://www.punekarnews.in/remarkable-jee-advanced-2023-results-at-prime-academy-pune-over-75-success-in-jee-mains-and-27-students-cracked-jee-advanced/'
    },
    {
      text: 'What is the road ahead for IITians after clearing JEE Advanced',
      linkText: 'READ NOW',
      link: 'https://www.punekarnews.in/what-is-the-road-ahead-for-iitians-after-clearing-jee-advanced-ias-ceo-researcher-or-bollywood/'
    },
    {
      text: 'Prime Alumni represents India on the international stage for his ground-breaking research',
      linkText: 'READ NOW',
      link: 'https://www.punekarnews.in/pune-researchers-deep-sea-pearl-finding-robot-impresses-pm-modi-during-us-visit/'
    },
    {
      text: "Pune's IIT Siblings : Stories of Victory and Parents' Joy",
      linkText: 'READ NOW',
      link: 'https://www.punekarnews.in/punes-iit-siblings-stories-of-victory-and-parents-joy/'
    },
    {
      text: "IIT JEE tips and tricks",
      linkText: 'READ NOW',
      link: 'https://www.punekarnews.in/iit-jee-tips-and-tricks-to-get-into-top-engineering-colleges/'
    },
    {
      text: "Expert guidance for Essential Last Day Strategies from Prime Academy",
      linkText: "READ NOW",
      link: "https://www.punekarnews.in/jee-mains-2023-essential-last-day-strategies-expert-guidance-from-prime-academy/"
    }
  ];

  testimonials = [
    {
      rank: 'AIR 01',
      name: 'Sushant Sachdeva',
      studentImageURL: 'assets/home/Testimonial/Sushant_Sachdeva.jpg',
      college: 'IIT Bombay, PhD at Princeton University, USA, B.Tech Computer Science and Engineering',
      testimonialDesc: 'Prof. Lalit was one of the best teachers that I have studied under when preparing for IIT JEE. I am sure many others will benefit from his dedication towards students.',
      video_url: 'https://www.youtube.com/embed/qdyDDCX47gQ'
    
    }, 
    {
      rank: 'AIR 22',
      name: 'Vaibhav Krishnan',
      studentImageURL: 'assets/home/Testimonial/vaibhav_krishan.jpg',
      college: 'IIT Bombay, B.Tech Computer Science',
      testimonialDesc: 'I could never thank Lalit Sir enough for the way he simplified the word "physics" for me. His dynamic approach to the subject converted page long solutions to single steps which was so crucial for me to secure AIR 22 in IIT-JEE where time matters the most. I would strongly recommend Real IIT-JEE aspirants to join Prime Academy under the guidance of Lalit Sir to crack IIT-JEE with the best ranks. Thanking a lot again to Lalit Sir.'
    },
    {
      rank: 'AIR 37',
      name: 'Ameya Ranade',
      studentImageURL: 'assets/home/Testimonial/Ameya_ranade.png',
      college: 'IIT Bombay, B.Tech Computer Engineering',
      testimonialDesc: 'You will seldom find someone who is so dynamic and master of almost all tricks to crack any type of problem in maths and physics. Lalit sir has got his own shortcuts for all topics in maths/physics, which made my IIT JEE preparation a cake walk. I will never forget those days when he went all guns blazing while teaching us electrodynamics. Solved many tough problems in his own style, which is any time superior than methods given in existing books. I am really thankful to him for helping me crack a two-digiti rank in IIT JEE. Sir,best wishes to U & all your lucky students. Sir U rock !!',
      video_url: 'https://www.youtube.com/embed/Gl3_hvIAaC0'
    },
    {
      rank: 'AIR 44',
      name: 'Sagar Sambrani',
      studentImageURL: 'assets/home/Testimonial/sagar_sambrani.jpg',
      college: 'IIT Bombay, B.Tech Electrical Engineering,Gold medallist IIM Kolkata.',
      testimonialDesc: `Being taught by <b>Lalit Sir has totally changed my attitude towards physics. His teaching makes complex concepts very simple to understand</b> which I feel is a must in a teacher. His lectures are fun to attend & so we could retain what was taught for longer periods of time. We really looked forward to attending Lalit Sir's lectures. I would <b>recommend students to learn Physics from Lalit Sir.</b>`
    },
    {
      rank: 'AIR 71',
      name: 'Amit Deshmane',
      studentImageURL: 'assets/home/Testimonial/amit_deshmane.jpg',
      college: 'IIT Kanpur, B. Tech Computer Science and Engineering',
      testimonialDesc: 'When I was in 10th I never thought I can be an IITian. <b>But luckily I got in contact with a genius called Lalit Sir</b>. He made me realize my strengths and motivated me to crack IIT. His immense knowledge <b>made my basics very strong</b> and then he gradually raised my level and made sure that IIT JEE became a child’s play. Throughout my JEE preparation days he kept on encouraging me, which really made a difference. I feel he is a genuine guide and I was lucky to be taught by him. If you have got killing instincts than he will make your path to IIT very simple. Just try.'
    },
  ];

  media = [
    {
        "id": 1,
        "thumbnail": "../../assets/home/Media/small/1.jpeg",
        "preview": "../../assets/home/Media/small/1.jpeg"
    },
    {
        "id": 2,
        "thumbnail": "../../assets/home/Media/small/2.jpeg",
        "preview": "../../assets/home/Media/small/2.jpeg"
    },
    {
        "id": 3,
        "thumbnail": "../../assets/home/Media/small/3.jpeg",
        "preview": "../../assets/home/Media/small/3.jpeg"
    },
    {
        "id": 4,
        "thumbnail": "../../assets/home/Media/small/4.jpeg",
        "preview": "../../assets/home/Media/small/4.jpeg"
    },
    {
        "id": 5,
        "thumbnail": "../../assets/home/Media/small/5.jpeg",
        "preview": "../../assets/home/Media/small/5.jpeg"
    },
    {
        "id": 6,
        "thumbnail": "../../assets/home/Media/small/6.jpeg",
        "preview": "../../assets/home/Media/small/6.jpeg"
    },
    {
        "id": 7,
        "thumbnail": "../../assets/home/Media/small/7.jpeg",
        "preview": "../../assets/home/Media/small/7.jpeg"
    },
    {
        "id": 8,
        "thumbnail": "../../assets/home/Media/small/8.jpeg",
        "preview": "../../assets/home/Media/small/8.jpeg"
    },
    {
        "id": 9,
        "thumbnail": "../../assets/home/Media/small/9.jpeg",
        "preview": "../../assets/home/Media/small/9.jpeg"
    },
    {
        "id": 10,
        "thumbnail": "../../assets/home/Media/small/10.jpeg",
        "preview": "../../assets/home/Media/small/10.jpeg"
    },
    {
        "id": 11,
        "thumbnail": "../../assets/home/Media/small/11.jpeg",
        "preview": "../../assets/home/Media/small/11.jpeg"
    },
    
  ];
  features = [
    {
      title: 'Mentors from IIT',
      img: '../../assets/images/static/mentors.png',
      desc: `
        Motivation plays a crucial role while preparing for competitive exams. 
        The interaction with a senior`,
      read_more_content: `        
        who has successfully navigated the same path and emerged as an IITian can be immensely motivational.
        The shared experiences and struggle stories from these seniors, who have overcome the same obstacles, 
        are invaluable advice. Students feel a deep connection with these seniors, often viewing them as role models
        for their future. 
        Prime Academy enjoys a unique advantage in maintaining connections with its alumni.
        As the same faculty has been instructing for over a decade, even graduates from as far back as 14 years ago
        regularly return to interact with their former mentors. This practice creates a positive and inspiring atmosphere,
        significantly benefiting current student.
        `,
      read_more_toggle: false
    },
    {
      title: 'Outstanding Results',
      img: '../../assets/images/static/outstanding-results.png',
      desc:
        'Prime Academy stands as a beacon of excellence in competitive exam preparation, boasting a remarkable success trajectory.',
      read_more_content: 'In a landscape where the national average for JEE Mains clearance is about 10%, and a mere 2% for JEE Advanced, Prime Academy consistently outperforms these metrics. An impressive 80% of its students navigate the challenges of JEE Mains successfully, while 35% emerge victorious in JEE Advanced.',
      read_more_toggle: false
    },
    {
      title: 'Trusted by Academicians',
      img: '../../assets/images/static/alumnais.png',
      desc:
        "Ensuring a bright future for their child is of utmost importance to every parent.",
      read_more_content: "While all parents try to identify the best options for their children, those from an academic background particularly leverage their deeper insight and experience to discern the right institute, often remaining unaffected by marketing gimmicks. We take immense pride in the fact that Prime Academy has successfully garnered the trust of many distinguished academicians. Among them is India’s premier physics author, DC Pandey, who relocated from Dehradun to Pune specifically to enroll his son at Prime Academy. He later joined us as a Physics faculty member and the Head of Department. Apart from this, the dean of prestigious institutes like IIT Bombay, principals of leading schools, as well as staff and faculty from other esteemed coaching institutes, have chosen Prime Academy for their own children's education, a testament to the unparalleled quality we offer.",
      read_more_toggle: false
    },
    {
      title: 'Stable Faculty Team',
      img: '../../assets/images/static/tests-series.png',
      desc:
        'Students studying in Institutes where the faculty remains stable and teaches throughout the course benefit from continuity.',
      read_more_link: '/blogs/read-more-stable-prime-faculty'
      
    },
  ];
  bsModalRef: BsModalRef;
  announcements = [];

  constructor(private modalService: BsModalService, private http: HttpClient, private analyticsService: AnalyticsService, public courseService: CourseService) {}
  innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    // console.log(this.deviceService.isMobile(), this.deviceService.isDesktop());
    this.innerWidth = window.innerWidth;
    this.analyticsService.trackEvent('homepage', 'home page loaded into view', 'VIEW_ENTER')

    this.fetch();

    this.announcements = [];

    this.http.get('https://primeacademypune.s3.ap-south-1.amazonaws.com/home/announcements.json', {responseType: 'blob'}).subscribe((announcements) => {
      announcements.text().then(text => {
        const data = JSON.parse(text);
        this.announcements = data;
        console.log(this.announcements);
      })
    })
  }

  gallery = [
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/K.JPG', path: '../../assets/images/dynamic/footer_fun/K.JPG'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/H.jpg', path: '../../assets/images/dynamic/footer_fun/H.jpg'}, 
    // {thumbnail: '../../assets/images/dynamic/footer_fun_small/jumping-high-950x350.jpg', path: '../../assets/images/dynamic/footer_fun/jumping-high-950x350.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/I.jpg', path: '../../assets/images/dynamic/footer_fun/I.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/M.jpg', path: '../../assets/images/dynamic/footer_fun/M.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/L.jpg', path: '../../assets/images/dynamic/footer_fun/L.jpg'}, 
    // {thumbnail: '../../assets/images/dynamic/footer_fun_small/lohagadh-relics-950x350.jpg', path: '../../assets/images/dynamic/footer_fun/lohagadh-relics-950x350.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/N.jpg', path: '../../assets/images/dynamic/footer_fun/N.jpg'}, 
    // {thumbnail: '../../assets/images/dynamic/footer_fun_small/Bridge-isnt-too-far-950x350.jpg', path: '../../assets/images/dynamic/footer_fun/Bridge-isnt-too-far-950x350.jpg'}, 
    // {thumbnail: '../../assets/images/dynamic/footer_fun_small/Prime-right-on-top-950x350.jpg', path: '../../assets/images/dynamic/footer_fun/Prime-right-on-top-950x350.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/J_1.jpg', path: '../../assets/images/dynamic/footer_fun/J_1.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/B.jpg', path: '../../assets/images/dynamic/footer_fun/B.jpg'}, 
    // {thumbnail: '../../assets/images/dynamic/footer_fun_small/On-the-way-950x350.jpg', path: '../../assets/images/dynamic/footer_fun/On-the-way-950x350.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/C.jpg', path: '../../assets/images/dynamic/footer_fun/C.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/D.jpg', path: '../../assets/images/dynamic/footer_fun/D.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/G.jpg', path: '../../assets/images/dynamic/footer_fun/G.jpg'}, 
    {thumbnail: '../../assets/images/dynamic/footer_fun_small/F.jpg', path: '../../assets/images/dynamic/footer_fun/F.jpg'}
  ]

  fetch() {
    // this.apiData = [
    //   {
    //     albumId: 1,
    //     id: '1',
    //     title: 'Best JEE Coaching Pune Prime Academy Pune',
    //     thumbnailUrl: '/assets/home/Carousel/BeyondTopRanks.jpeg',
    //     url: '/assets/home/Carousel/BeyondTopRanks.jpeg'
    //   },
    //   {
    //     albumId: 2,
    //     id: '2',
    //     title: 'Best JEE Coaching Pune Prime Academy Pune',
    //     url: '/assets/home/Carousel/best-jee-coaching-pune-prime-academy-pune.jpg',
    //     thumbnailUrl: '/assets/home/Carousel/best-jee-coaching-pune-prime-academy-pune.jpg',
    //   },
    // ]
    this.apiData = [
      {
        albumId: 1,
        id: '0',
        title: 'Best JEE Coaching Pune Prime Academy Pune',
        thumbnailUrl: '/assets/home/Carousel/iit-jee-top-ranks-prime-academy-pune-2024.jpeg',
        url: '/assets/home/Carousel/iit-jee-top-ranks-prime-academy-pune-2024.jpeg'
      },
      {
        albumId: 1,
        id: '1',
        title: 'Best JEE Coaching Pune Prime Academy Pune',
        thumbnailUrl: '/assets/home/Carousel/BeyondTopRanks.jpeg',
        url: '/assets/home/Carousel/BeyondTopRanks.jpeg'
      },
      {
        albumId: 1,
        id: '2',
        title: 'Best JEE Coaching Pune Prime Academy Pune',
        url: '/assets/home/Carousel/best-jee-coaching-pune-prime-academy-pune.jpg',
        thumbnailUrl: '/assets/home/Carousel/best-jee-coaching-pune-prime-academy-pune.jpg',
      },
      {
        albumId: 1,
        id: '3',
        title: 'IIT JEE Top Ranks Prime Academy Pune',
        url: '/assets/home/Carousel/iit-jee-top-ranks-prime-academy-pune.jpg',
        thumbnailUrl: '/assets/home/Carousel/iit-jee-top-ranks-prime-academy-pune.jpg',
      },
      {
        albumId: 1,
        id: '4',
        title: 'Best JEE NEET Coaching Pune Prime Academy',
        url: '/assets/home/Carousel/best-jee-neet-coaching-pune-prime-academy.jpg',
        thumbnailUrl: '/assets/home/Carousel/best-jee-neet-coaching-pune-prime-academy.jpg',
      },
      {
        albumId: 1,
        id: '5',
        title: 'Best IIT JEE Results Pune Prime Academy',
        url: '/assets/home/Carousel/best-iit-jee-results-pune-prime-academy.jpg',
        thumbnailUrl: '/assets/home/Carousel/best-iit-jee-results-pune-prime-academy.jpg',
      },
      {
        albumId: 1,
        id: '6',
        title: 'Best Classes For JEE Pune Prime Academy',
        url: '/assets/home/Carousel/best-classes-for-jee-pune-prime-academy.jpg',
        thumbnailUrl: '/assets/home/Carousel/best-classes-for-jee-pune-prime-academy.jpg',
      },
      {
        albumId: 1,
        id: '7',
        title: 'How to Prepare for IIT JEE Prime Academy',
        url: '/assets/home/Carousel/how-to-prepare-for-iit-jee-prime-academy.jpg',
        thumbnailUrl: '/assets/home/Carousel/how-to-prepare-for-iit-jee-prime-academy.jpg',
      },
      // {
      //   albumId: 1,
      //   id: '4',
      //   title: 'culpa odio esse rerum omnis laboriosam voluptate repudiandae',
      //   url: 'https://via.placeholder.com/600/d32776',
      //   thumbnailUrl: 'https://via.placeholder.com/150/d32776',
      // },
      // {
      //   albumId: 1,
      //   id: '5',
      //   title: 'natus nisi omnis corporis facere molestiae rerum in',
      //   url: 'https://via.placeholder.com/600/f66b97',
      //   thumbnailUrl: 'https://via.placeholder.com/150/f66b97',
      // },
      // {
      //   albumId: 1,
      //   id: '6',
      //   title: 'accusamus ea aliquid et amet sequi nemo',
      //   url: 'https://via.placeholder.com/600/56a8c2',
      //   thumbnailUrl: 'https://via.placeholder.com/150/56a8c2',
      // },
      // {
      //   albumId: 1,
      //   id: '7',
      //   title:
      //     'officia delectus consequatur vero aut veniam explicabo molestias',
      //   url: 'https://via.placeholder.com/600/b0f7cc',
      //   thumbnailUrl: 'https://via.placeholder.com/150/b0f7cc',
      // },
      // {
      //   albumId: 1,
      //   id: '8',
      //   title: 'aut porro officiis laborum odit ea laudantium corporis',
      //   url: 'https://via.placeholder.com/600/54176f',
      //   thumbnailUrl: 'https://via.placeholder.com/150/54176f',
      // },
      // {
      //   albumId: 1,
      //   id: '9',
      //   title: 'qui eius qui autem sed',
      //   url: 'https://via.placeholder.com/600/51aa97',
      //   thumbnailUrl: 'https://via.placeholder.com/150/51aa97',
      // },
      // {
      //   albumId: 1,
      //   id: '10',
      //   title: 'beatae et provident et ut vel',
      //   url: 'https://via.placeholder.com/600/810b14',
      //   thumbnailUrl: 'https://via.placeholder.com/150/810b14',
      // },
    ];
  }
  openTestimonialViewAllOrVideoModal(
    index: number,
    viewAll: boolean,
    viewVideo: boolean,
    viewMedia: boolean
  ) {
    const initialState = {
      isTestimonialViewAllView: viewAll,
      isTestimonialVideoView: viewVideo,
      testimonialItem: this.testimonials[index],
      testimonialItems: this.testimonials,
      currentIndex: index,
      isMedia: viewMedia,
      medias: this.media,
    };
    this.bsModalRef = this.modalService.show(PopupModalComponent, {
      initialState,
      class: 'modal-lg',
      animated: true,
      keyboard: true,
      backdrop: true,
      ignoreBackdropClick: false,
    });
    this.bsModalRef.content.closeBtnName = 'Close';
  }
}
