import { Component, OnInit, HostListener } from '@angular/core';
import { AnalyticsService } from '../services/analytics.sevice';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  
  active = 0;
  read_more_1 = false;
  read_more_2 = false;

  constructor(private analyticsService: AnalyticsService) { }
  innerWidth: any;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
  }
  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.analyticsService.trackEvent('about_us', 'about_us view loaded', 'VIEW_ENTER')
  }

  get_height(){
    if (this.innerWidth > 992){
      console.log(0, this.read_more_1 ? '1010px' : '900px')
      return this.read_more_1 ? '1010px' : '500px'
    }
    else if (this.innerWidth > 768){
      console.log(1, this.read_more_1 ? '1010px' : '1100px')
      return this.read_more_1 ? '1010px' : '700px'
    }
    else{
      console.log(2, this.innerWidth, this.read_more_1 ? '1010px' : '900px')
      return this.read_more_1 ? '1900px' : '965px'
    }
  }

  public toggle(value) {
    this.active = value;
    console.log(this.active);
  }

}
