<ul id="accordion" class="accordion">
  <li *ngFor="let menu of menus; let i = index" [class.active]="menu.active">
    <div class="menu" (click)="toggle(i)">
      <!-- <i [class]="menu.iconClass"></i> -->
      {{ menu.name }}
      <i class="fa fa-chevron-down"></i>
    </div>
    <ul
      class="submenu"
      #submenu
      [style.height.px]="menu.active ? submenu.scrollHeight : 0"
    >
      <li *ngFor="let submenu of menu.submenu">
        <p [innerHTML]="submenu.name"></p>
      </li>
    </ul>
  </li>
</ul>
