<div class="container">
    <div class="row">
        <div class="col text-center">
           <h1><span class="border-bottom pb-2">Unlock Success with IIT JEE Top Scorers</span></h1>
        </div>
    </div>
    <div class="row py-4">
        <div class="col-lg-6 p-3">
            <div class="video-container">
                <iframe src="https://www.youtube.com/embed/BmOxfPdyzWo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-6  d-flex align-items-center">
            Diving into JEE and NEET preparation? Gaining insights from those who've not only walked the path but also emerged in the top 0.01% can be invaluable. Tune into this video as Mustafa and Huzefa Chasmai, the CSE duo from IIT, unveil their strategies and secrets.
            Their journey and wisdom might just be the guiding light you need.
    </div>
</div>