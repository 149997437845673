<div class="container">
    <div class="row">
        <div class="col text-center">
           <h1><span class="border-bottom pb-2">Revamping for Victory: The Repeater's Batch Journey</span></h1>
        </div>
    </div>
    <div class="row py-4">
        <div class="col-lg-6 p-3">
            <div class="video-container">
                <iframe src="https://www.youtube.com/embed/oURWsNnd4Yo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
        </div>
        <div class="col-lg-6  d-flex align-items-center">
            Considering another shot at your dream college, whether it's IIT or AFMC? Gain valuable insights to make your repeater's journey highly effective. Hear from Mrunmayee Bhalerao as she recounts her transformative experience, from negative scores in JEE Mains to Computer Science at IIT Delhi, all after joining the repeaters' batch at Prime Academy. If you're reattempting JEE/NEET in 2024, this video is indispensable.
        </div>
    </div>
</div>