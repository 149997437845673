<app-header [class.fixed]="navFixed"></app-header>
<main>
  <router-outlet></router-outlet>
</main>
<app-footer></app-footer>

<article class="container-fluid prime-ads">
  <div style="padding-right: 100px !important" class="desc-1 col-md-8 col-sm-12">
    Prior to joining an institute, it's highly recommended to acquaint yourself with the quality of teaching, operating system, and overall culture. Prime Academy offers this opportunity, ensuring utmost transparency in its approach.

  </div>
  <div class="buttons col-md-4 col-sm-12">
    <button class="p-ad-btn-red" routerLink="/forms/common/book_demo">BOOK DEMO</button>
    <button class="p-ad-btn-white mar-32" routerLink="/forms/common/book_appointment">BOOK APPOINTMENT</button>
  </div>
</article>
<div class="footer-end"></div>
